import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";

import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { odoToast } from "lib/odoToast";
import { useNavigate } from "react-router-dom";

const UIPlaygroundRoute = () => {
  const navigate = useNavigate();

  return (
    <Rows>
      <LabeledFormInput label="Toasts">
        <Columns className="gap-md">
          <Button
            text="Success"
            variant="outline"
            onClick={() =>
              odoToast.success({
                title: "Some title",
                text: "A description of something successful",
              })
            }
          />
          <Button
            text="Success With CTA"
            variant="outline"
            onClick={() =>
              odoToast.success({
                title: "Some title",
                text: "A description of something successful",
                cta: {
                  text: "View",
                  onClick: () => {
                    console.log("View");
                  },
                },
              })
            }
          />
          <Button
            text="Success With CTA Undo"
            variant="outline"
            onClick={() =>
              odoToast.success({
                title: "Some title",
                text: "A description of something successful",
                cta: {
                  text: "View",
                  onClick: () => {
                    navigate("/admin/");
                  },
                  undo: {
                    text: "Back",
                    onClick: () => {
                      navigate(-1);
                    },
                  },
                },
              })
            }
          />
        </Columns>
        <Columns className="gap-md">
          <Button
            text="Warning"
            variant="outline"
            onClick={() =>
              odoToast.warning({
                title: "Some title",
                text: "A description of something successful",
              })
            }
          />
          <Button
            text="Warning With CTA"
            variant="outline"
            onClick={() =>
              odoToast.warning({
                title: "Some title",
                text: "A description of something successful",
                cta: {
                  text: "View",
                  onClick: () => {
                    console.log("View");
                  },
                },
              })
            }
          />
          <Button
            text="Warning With CTA Undo"
            variant="outline"
            onClick={() =>
              odoToast.warning({
                title: "Some title",
                text: "A description of something successful",
                cta: {
                  text: "View",
                  onClick: () => {
                    navigate("/admin/");
                  },
                  undo: {
                    text: "Back",
                    onClick: () => {
                      navigate(-1);
                    },
                  },
                },
              })
            }
          />
        </Columns>
        <Columns className="gap-md">
          <Button
            text="Error"
            variant="outline"
            onClick={() =>
              odoToast.error({
                title: "Some title",
                text: "A description of something bad",
              })
            }
          />
          <Button
            text="Error With CTA"
            variant="outline"
            onClick={() =>
              odoToast.error({
                title: "Some title",
                text: "A description of something bad",
                cta: {
                  text: "View",
                  onClick: () => {
                    console.log("View");
                  },
                },
              })
            }
          />
          <Button
            text="Error With CTA Undo"
            variant="outline"
            onClick={() =>
              odoToast.error({
                title: "Some title",
                text: "A description of something bad",
                cta: {
                  text: "View",
                  onClick: () => {
                    navigate("/admin/");
                  },
                  undo: {
                    text: "Back",
                    onClick: () => {
                      navigate(-1);
                    },
                  },
                },
              })
            }
          />
          <Button
            text="Caught Error"
            variant="outline"
            onClick={() => odoToast.caughtError(new Error("Test error"))}
          />
          <Button
            text="Caught Error With Action"
            variant="outline"
            onClick={() =>
              odoToast.caughtError(new Error("Test error"), "Action")
            }
          />
          <Button
            text="Caught Error Object"
            variant="outline"
            onClick={() => odoToast.caughtError({ hello: "world" })}
          />
        </Columns>
      </LabeledFormInput>
    </Rows>
  );
};

export default UIPlaygroundRoute;
