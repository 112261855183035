import { RFPLabel } from "api/Api";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Icon from "components/common/Icon";
import { ResultBase } from "components/common/menus/ComboBox";
import PaginatedComboBox from "components/common/menus/PaginatedComboBox";
import Pill from "components/common/Pill";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import usePaginatedData from "hooks/usePaginatedData";
import { odoToast } from "lib/odoToast";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useState } from "react";

interface RFPLabelDropDownProps {
  selectedLabels: RFPLabel[];
  onToggleLabel: (label: RFPLabel) => void;
  allowCreating: boolean;
  variant?: "list" | "input";
  className?: string;
}

interface RFPLabelResult extends ResultBase {
  id: string;
  name: string;
}

const RFPLabelDropDown: FC<RFPLabelDropDownProps> = ({
  selectedLabels: labels,
  onToggleLabel,
  allowCreating,
  className,
  variant = "list",
}) => {
  let previewContent = null;
  switch (variant) {
    case "list":
      previewContent = (
        <Rows className="gap-xs mt-xs items-end w-full">
          {labels.length === 0 && (
            <p className="text-primary">
              <Icon
                name="chevron-down"
                className="rounded-full border border-primary p-xs text-xs"
              />
            </p>
          )}
          {labels.map((l) => (
            <Pill
              key={l.id}
              text={l.name}
              className="bg-background max-w-full"
            />
          ))}
        </Rows>
      );
      break;
    case "input":
      previewContent = (
        <div
          className={cn(
            "m-thin bg-background-selected rounded-md px-md overflow-hidden break-none w-full flex items-center",
            labels.length === 0 && "text-secondary"
          )}
        >
          <Icon name="filter" className="mr-md text-secondary" />
          {labels.map((l) => (
            <Pill
              key={l.id}
              text={l.name}
              className="inline mr-xs bg-background"
            />
          ))}
          {labels.length === 0 && (
            <span className="font-sm">Filter by label</span>
          )}
        </div>
      );
      break;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={cn("flex items-stretch", className)}>
        {previewContent}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <RFPLabelDropDownContent
          selectedLabels={labels}
          onToggleLabel={onToggleLabel}
          allowCreating={allowCreating}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const RFPLabelDropDownContent: FC<RFPLabelDropDownProps> = ({
  selectedLabels: labels,
  allowCreating,
  onToggleLabel,
}) => {
  const apiClient = useApiClient();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const selectedIds = labels.map((l) => l.id!);

  const handleCreateLabel = async (name: string) => {
    try {
      setIsCreating(true);
      const response = await apiClient.rfp.rfpRfpLabelCreate({ name });
      onToggleLabel({
        id: response.data.id,
        name: response.data.name,
      });
    } catch (error) {
      odoToast.caughtError(error, "Creating Label");
    } finally {
      setIsCreating(false);
    }
  };

  const [options, , paginatedData] = usePaginatedData<RFPLabelResult, RFPLabel>(
    {
      endpoint: apiClient.rfp.rfpRfpLabelList,
      map: (remote) => ({
        id: remote.id!.toString(),
        name: remote.name,
      }),
    }
  );

  let allOptions: RFPLabelResult[] | null = null;
  if (options && !isCreating) {
    if (
      allowCreating &&
      paginatedData.search !== "" &&
      !options.find((value) => value.name === paginatedData.search)
    ) {
      allOptions = options.concat([{ id: "new", name: paginatedData.search }]);
    } else {
      allOptions = options;
    }
  }

  return (
    <PaginatedComboBox
      results={allOptions}
      placeholder={allowCreating ? "Search or add new" : "Search labels"}
      paginatedData={paginatedData}
      defaultKeyboardFocusIndex={null}
      renderResult={(option) => (
        <Columns className="items-center">
          <div className="w-[24px]">
            {option.id === "new" ? (
              <Icon name="plus" />
            ) : (
              selectedIds.includes(parseInt(option.id)) && <Icon name="check" />
            )}
          </div>
          <Rows>{option.name}</Rows>
        </Columns>
      )}
      autoFocus={true}
      alwaysShowResults={true}
      onSelect={(option) => {
        if (option.id === "new") {
          handleCreateLabel(option.name);
        } else {
          onToggleLabel({
            id: parseInt(option.id),
            name: option.name,
          });
        }
      }}
    />
  );
};

export default RFPLabelDropDown;
