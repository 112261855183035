import NavigationView from "components/common/NavigationView";
import FullRFPView from "components/rfps/FullRFPView";
import { ShortListsProvider } from "providers/ShortListsProvider";
import { useParams } from "react-router-dom";

const RFPDetailsRoute = () => {
  const { id } = useParams();

  return (
    <NavigationView scrollable={false} fullWidth={true} padContent={false}>
      <ShortListsProvider>
        <FullRFPView
          rfp={id!}
          showTitle={true}
          intent="all"
          className="pt-lg"
          constrainWidth={true}
        />
      </ShortListsProvider>
    </NavigationView>
  );
};
export default RFPDetailsRoute;
