import Rows from "components/common/containers/Rows";
import {
  isSavedSearchDraft,
  SearchConfiguratorProps,
} from "./SearchConfiguratorProps";
import Columns from "components/common/containers/Columns";
import { useConfirm, useTextAreaConfirm } from "providers/AlertProvider";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { SavedSearchList } from "api/Api";
import Icon from "components/common/Icon";
import { odoToast } from "lib/odoToast";

const SavedSearchConfigurator = ({
  search,
  updateSearch,
  close,
  refresh,
}: SearchConfiguratorProps) => {
  const textAreaConfirm = useTextAreaConfirm();
  const confirm = useConfirm();
  const apiClient = useApiClient();
  const [savedSearches, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpSavedSearchList,
    map: (data) => data,
  });
  const handleSaveDraft = async () => {
    close();
    const result = await textAreaConfirm("Enter a name for your new search", {
      placeholder: search.name ?? "Saved search name",
      multiline: false,
      defaultId: "save",
      buttons: [
        {
          text: "Cancel",
          variant: "solid-secondary",
          id: "cancel",
        },
        {
          text: "Save",
          variant: "solid",
          id: "save",
        },
      ],
    });
    if (result.id !== "save") return;

    updateSearch({
      name: result.text,
      draft_for_user_id: null,
    });
  };

  const handleUpdateDraft = async (toSearch: SavedSearchList) => {
    const result = await confirm("Update Saved Search", {
      body: `Are you sure you want to overwrite ${
        search.name ?? "this search"
      }?`,
      yesText: "Overwrite",
      noText: "Cancel",
    });
    if (!result) return;

    const response = await apiClient.rfp.rfpSavedSearchChooseCreate(
      toSearch.id.toString(),
      {
        copy_search_id: search.id,
      }
    );
    updateSearch(response.data, false);
    close();
    odoToast.success({
      title: "Search Updated",
      text: `${toSearch.display_name} has been updated`,
    });
  };

  const isDraft = isSavedSearchDraft(search);
  return (
    <Rows className="gap-md pt-sm">
      <p className="text-sm text-secondary">
        {isDraft
          ? "Save the current search configuration as a new search or update an existing one"
          : "Save your search configurations for your team's easy access"}
      </p>
      {isDraft && (
        <PaginatedTableView<SavedSearchList | "new">
          results={
            savedSearches === null
              ? null
              : (savedSearches as (SavedSearchList | "new")[]).concat(["new"])
          }
          paginatedData={paginatedData}
          columns={[]}
          onSelect={(search) => {
            if (search === "new") {
              handleSaveDraft();
            } else {
              handleUpdateDraft(search);
            }
          }}
          renderRow={(search, Cell, Row) => (
            <Row key={search === "new" ? "new" : search.id}>
              {search === "new" ? (
                <Cell className="bg-background-popover">
                  <Columns className="gap-sm items-center">
                    <Icon name="plus" />
                    <p>New Search</p>
                  </Columns>
                </Cell>
              ) : (
                <Cell className="bg-background-popover">
                  {search.display_name}
                </Cell>
              )}
            </Row>
          )}
        />
      )}
    </Rows>
  );
};

export default SavedSearchConfigurator;
