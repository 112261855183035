import { CategoryPicker } from "components/profile/CategoryPicker";
import { SearchConfiguratorProps } from "./SearchConfiguratorProps";
import { FC } from "react";
import Rows from "components/common/containers/Rows";

const CategoryConfigurator: FC<SearchConfiguratorProps> = ({
  search,
  updateSearch,
}) => {
  return (
    <Rows>
      <p className="font-semibold text-secondary">Standard Categories</p>
      <CategoryPicker
        categories={search.categories}
        categoryDisplayNames={search.category_display_names ?? {}}
        onChange={(categories, sampleCategory) => {
          updateSearch({
            categories,
          });
        }}
      />
    </Rows>
  );
};

export default CategoryConfigurator;
