import usePaginatedData from "hooks/usePaginatedData";
import useTriggerDownload from "hooks/useTriggerDownload";
import { useTextAreaConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, useState } from "react";
import { odoToast } from "lib/odoToast";
import PaginatedTableView, {
  Column,
} from "./common/containers/PaginatedTableView";
import Rows from "./common/containers/Rows";
import Button from "./common/Button";
import Columns from "./common/containers/Columns";
import Spacer from "./common/containers/Spacer";
import { format_datetime } from "lib/utils";
import AddPastProposalOverlay from "./proposal/AddPastProposalOverlay";

interface PastProposalListProps {
  proposalId?: string;
  disableAdding?: boolean;
}

interface PastProposal {
  id: string;
  name: string;
  created: string;
  isProcessed: boolean;
  topic?: string;
  inProposal?: boolean;
}

const PastProposalList: FC<PastProposalListProps> = ({
  proposalId,
  disableAdding,
}) => {
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const { isStaff } = useAuthenticatedUser();
  const textAreaConfirm = useTextAreaConfirm();
  const [isAdding, setIsAdding] = useState(false);

  const [proposals, setProposals, paginatedData] = usePaginatedData({
    endpoint: (options) => {
      if (proposalId) {
        return apiClient.rfp.rfpProposalPastList(proposalId, options);
      }
      return apiClient.rfp.rfpProposalPastListList(options);
    },
    map: (proposal) => ({
      id: proposal.id!,
      name: proposal.name!,
      created: proposal.created!,
      isProcessed: proposal.is_processed!,
      topic: proposal.topic ?? undefined,
      inProposal: proposal.in_proposal ?? false,
    }),
  });

  const getDetials = async (id: string) => {
    try {
      if (proposalId) {
        const response = await apiClient.rfp.rfpProposalPastRead2(
          proposalId,
          id
        );
        return response.data;
      } else {
        const response = await apiClient.rfp.rfpProposalPastRead(id);
        return response.data;
      }
    } catch (e) {
      odoToast.caughtError(e, "Getting Past Proposal Details");
      return null;
    }
  };

  const handleView = async (id: string) => {
    const details = await getDetials(id);
    if (!details) {
      return;
    }

    if (!details.view_url) {
      odoToast.error({
        title: "Error Viewing Proposal",
        text: "No PDF available",
      });
      return;
    }

    triggerDownload(details.view_url, "tab");
  };

  const handleViewMarkdown = async (id: string) => {
    const details = await getDetials(id);
    if (!details) {
      return;
    }
    if (!details.markdown_url) {
      odoToast.error({
        title: "Error Viewing Markdown",
        text: "No markdown available",
      });
      return;
    }
    triggerDownload(details.markdown_url);
  };

  const handleSetTopicForProposal = async (proposal: PastProposal) => {
    const result = await textAreaConfirm("Testing", {
      initialText: proposal.topic,
      buttons: [
        { id: "cancel", text: "Cancel", variant: "outline" },
        {
          id: "save",
          text: "Save",
          icon: "floppy-disk",
          variant: "solid",
        },
      ],
    });
    if (result.id === "save") {
      try {
        // @ts-ignore
        await apiClient.rfp.rfpProposalPastPartialUpdate(proposal.id, {
          topic: result.text,
        });

        setProposals((proposals) =>
          proposals!.map((p) =>
            p.id === proposal.id ? { ...p, topic: result.text } : p
          )
        );
      } catch (e) {
        odoToast.caughtError(e, "Updating Topic");
      }
    }
  };

  let columns: Column[] = [
    { name: "Name" },
    { name: "Created", size: "min" },
    { name: "View", size: "min" },
  ];

  if (isStaff) {
    columns.push(
      { name: "Processed", size: "min" },
      { name: "Markdown", size: "min" },
      { name: "Topic", size: "min" }
    );
  }

  let segmentedProposals: (PastProposal | string)[] | null = proposals;
  if (proposals) {
    let showSegments = proposals.length > 0 && proposals[0].inProposal === true;
    if (showSegments) {
      segmentedProposals = [];
      let foundNotInProposal = false;
      for (let i = 0; i < proposals.length; i++) {
        if (i === 0) {
          segmentedProposals.push("Recommended");
        }
        if (!foundNotInProposal && !(proposals[i].inProposal ?? false)) {
          segmentedProposals.push("Other");
          foundNotInProposal = true;
        }
        segmentedProposals.push(proposals[i]);
      }
    }
  }

  return (
    <Rows className="grow">
      <Columns className="flex mb-md shrink-0 items-center">
        <h1 className="text-2xl font-semibold w-full mb-md">Past Proposals</h1>
        <Spacer />
        {!disableAdding && (
          <Button
            text="Add Past Proposal"
            variant="solid"
            className="shrink-0"
            icon="plus"
            onClick={() => setIsAdding(true)}
          />
        )}
      </Columns>
      <PaginatedTableView
        searchMode="search"
        results={segmentedProposals}
        className="grow"
        paginatedData={paginatedData}
        columns={columns}
        renderRow={(proposal, Cell, Row, index) => {
          if (typeof proposal === "string") {
            return (
              <Row key={proposal}>
                <Cell className="font-semibold bg-background-selected text-secondary font-thin text-sm py-xs col-span-full">
                  {proposal}
                </Cell>
              </Row>
            );
          }
          return (
            <Row key={proposal.id}>
              <Cell>{proposal.name}</Cell>
              <Cell>{format_datetime(proposal.created)}</Cell>
              <Cell>
                <button onClick={() => handleView(proposal.id)}>View</button>
              </Cell>
              {isStaff && (
                <>
                  <Cell>{proposal.isProcessed ? "Yes" : "No"}</Cell>
                  <Cell>
                    <button onClick={() => handleViewMarkdown(proposal.id)}>
                      Markdown
                    </button>
                  </Cell>
                  <Cell>
                    <button onClick={() => handleSetTopicForProposal(proposal)}>
                      {proposal.topic ?? "-"}
                    </button>
                  </Cell>
                </>
              )}
            </Row>
          );
        }}
      />
      {isAdding && (
        <AddPastProposalOverlay
          onClose={() => {
            setIsAdding(false);
            paginatedData.refresh();
          }}
        />
      )}
    </Rows>
  );
};

export default PastProposalList;
