import {
  DropdownMenuItemProps,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { RFPSearch } from "api/Api";
import StartNewProposalOverlay from "app/home/StartNewProposalOverlay";
import Button, { ButtonProps } from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Icon, { IconName } from "components/common/Icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "components/EditorView/Menus/DropdownMenu";
import { useTextAreaConfirm } from "providers/AlertProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useListsForRFP } from "providers/ShortListsProvider";
import { FC, ReactNode, useState } from "react";

export type RFPIntent =
  // The user is looking to shortlist RFPs
  | "shortlist"
  // The user is making a go/no-go decision on an RFP
  | "go-no-go"
  // The user is on the RFP dedicated page
  // All actions are available
  | "all";

interface RFPOptionsViewProps {
  rfp: RFPSearch;
  intent: RFPIntent;
  // A specific list to add/remove from
  primaryListId?: string;
}

const RFPOptionsView: FC<RFPOptionsViewProps> = ({
  rfp,
  intent,
  primaryListId,
}) => {
  switch (intent) {
    case "shortlist":
      return <ShortlistActions rfp={rfp} primaryListId={primaryListId} />;
    case "go-no-go":
      return <GoNoGoActions rfp={rfp} primaryListId={primaryListId} />;
    case "all":
      return (
        <>
          <ShortlistActions rfp={rfp} primaryListId={primaryListId} />
          <GoNoGoActions rfp={rfp} primaryListId={primaryListId} />
        </>
      );
  }
};

interface SecondaryActionProps extends Omit<DropdownMenuItemProps, "children"> {
  text: string;
  icon?: IconName;
  extraIcon?: IconName;
}

interface RFPOptionState {
  primaryAction: ButtonProps;
  secondaryActions: (SecondaryActionProps | "divider")[];
  otherAction?: ButtonProps;
  overlays?: ReactNode;
}

const OptionsStateView: FC<{ state: RFPOptionState }> = ({ state }) => {
  const dropDownContent =
    state.secondaryActions.length > 0 ? (
      <DropdownMenuContent>
        {state.secondaryActions.map((action) =>
          action === "divider" ? (
            <div key="divider" className="h-[0.5px] bg-border" />
          ) : (
            <DropdownMenuItem key={action.text} {...action}>
              {action.text}
              {action.extraIcon && (
                <Icon
                  name={action.extraIcon}
                  variant="solid"
                  className="ml-auto"
                />
              )}
            </DropdownMenuItem>
          )
        )}
      </DropdownMenuContent>
    ) : null;

  if (!state.primaryAction.onClick) {
    // Show single button triggering dropdown
    return (
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button visualOnly {...state.primaryAction} />
        </DropdownMenuTrigger>
        {dropDownContent}
      </DropdownMenu>
    );
  } else {
    // Show primary action button with dropdown side button
    return (
      <Columns>
        {state.otherAction && (
          <Button text={state.otherAction.text} {...state.otherAction} />
        )}
        <Button
          text={state.primaryAction.text}
          variant="outline"
          className={
            state.secondaryActions.length > 0 ? "rounded-none rounded-l-sm" : ""
          }
          {...state.primaryAction}
        />
        {state.secondaryActions.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger className="rounded-none rounded-r-sm border border-l-0 w-[32px]">
              <Icon
                name="chevron-down"
                className="rounded-none rounded-r-sm border-l-0"
              />
            </DropdownMenuTrigger>
            {dropDownContent}
          </DropdownMenu>
        )}
      </Columns>
    );
  }
};

const ShortlistActions: FC<{
  rfp: RFPSearch;
  primaryListId?: string;
}> = ({ rfp, primaryListId }) => {
  const { favoriteListId } = useAuthenticatedUser();
  const textAreaConfirm = useTextAreaConfirm();
  const {
    inLists,
    allLists,
    addRFPToList,
    addRFPToNewList,
    removeRFPFromList,
  } = useListsForRFP(rfp.id);

  let removeFromListActions: (SecondaryActionProps | "divider")[] =
    Object.entries(inLists).map(
      ([id, list]): SecondaryActionProps => ({
        text: "Remove from " + list.name,
        onClick: () => removeRFPFromList(id),
      })
    );
  if (removeFromListActions.length > 0) {
    removeFromListActions.unshift("divider");
  }

  const handleAddNewList = async () => {
    const result = await textAreaConfirm("Add to New List", {
      placeholder: "Enter the name of the new list",
      buttons: [
        {
          text: "Cancel",
          id: "cancel",
        },
        {
          text: "Save",
          id: "save",
          variant: "solid",
        },
      ],
    });

    if (result.id !== "save" || !result.text) {
      return;
    }

    addRFPToNewList(result.text);
  };

  let primaryAction: ButtonProps;
  let secondaryActions: SecondaryActionProps[] = [];
  if (Object.keys(allLists).length === 0) {
    primaryAction = {
      text: "Add to List",
      variant: "solid",
      onClick: handleAddNewList,
    };
  } else if (Object.keys(allLists).length === 1) {
    // With a single list, simple add and remove buttons
    if (Object.keys(inLists).length === 0) {
      primaryAction = {
        text: "Add to List",
        variant: "solid",
        onClick: () => addRFPToList(Object.keys(allLists)[0]),
      };
    } else {
      primaryAction = {
        text: "Remove from List",
        variant: "outline",
        onClick: () => removeRFPFromList(Object.keys(allLists)[0]),
      };
    }
  } else {
    // With multiple lists, always include the same dropdown with toggles for each list
    // + adding a new list
    secondaryActions = Object.entries(allLists)
      .sort(([, list1], [, list2]) => list1.name.localeCompare(list2.name))
      .map(([id, list]): SecondaryActionProps => {
        return {
          icon: inLists[id] ? "square-check" : "square",
          text: list.name,
          extraIcon: favoriteListId === id ? "star" : undefined,
          onClick: () => {
            if (inLists[id]) {
              removeRFPFromList(id);
            } else {
              addRFPToList(id);
            }
          },
        };
      })
      .concat([
        {
          icon: "plus",
          text: "Add to New List",
          onClick: handleAddNewList,
        },
      ]);

    if (favoriteListId && allLists[favoriteListId]) {
      // With multiple + favorite list, offer default action with secondary actions
      if (inLists[favoriteListId]) {
        primaryAction = {
          text: `Remove from ${allLists[favoriteListId].name}`,
          iconVariant: "solid",
          variant: "outline",
          onClick: () => removeRFPFromList(favoriteListId),
        };
      } else {
        primaryAction = {
          text: `Add to ${allLists[favoriteListId].name}`,
          variant: Object.keys(inLists).length > 0 ? "outline" : "solid",
          iconVariant: "solid",
          onClick: () => addRFPToList(favoriteListId),
        };
      }
    } else {
      // With multiple lists + no favorite, always use dropdown
      if (Object.keys(inLists).length === 0) {
        primaryAction = {
          text: "Add to List",
          variant: "solid",
          // Leaving onClick empty to trigger dropdown
        };
      } else {
        primaryAction = {
          text: "Manage Lists",
          variant: "outline",
          // Leaving onClick empty to trigger dropdown
        };
      }
    }
  }

  return (
    <OptionsStateView
      state={{
        primaryAction,
        secondaryActions,
      }}
    />
  );
};

const GoNoGoActions: FC<{ rfp: RFPSearch; primaryListId?: string }> = ({
  rfp,
  primaryListId,
}) => {
  const [startingProposal, setStartingProposal] = useState(false);
  const { allLists, removeRFPFromList } = useListsForRFP(rfp.id);

  const removeRFPFromAllLists = () => {
    Object.keys(allLists).forEach((listId) => removeRFPFromList(listId));
  };

  return (
    <OptionsStateView
      state={{
        primaryAction: {
          text: "Start Proposal",
          variant: "solid",
          onClick: () => {
            removeRFPFromAllLists();
            setStartingProposal(true);
          },
        },
        secondaryActions: [],
        otherAction: primaryListId
          ? {
              text: "Remove",
              onClick: () => removeRFPFromList(primaryListId),
            }
          : undefined,
        overlays: (
          <StartNewProposalOverlay
            open={startingProposal}
            existingRfp={{ id: rfp.id, title: rfp.display_name }}
            onClose={() => setStartingProposal(false)}
          />
        ),
      }}
    />
  );
};

export default RFPOptionsView;
