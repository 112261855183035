import { RFPSearch } from "api/Api";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { ShortList, useShortLists } from "providers/ShortListsProvider";
import { useEffect, useState } from "react";

export interface SingleList {
  id: "single";
  listId: string;
  listName: string;
  rfps: RFPSearch[];
  hasOtherLists: boolean;
  deleteList: () => void;
  renameList: (newName: string) => void;
  addNewList: (listName: string) => string;
}

export interface MultipleLists {
  id: "multiple";
  lists: Record<string, ShortList>;
  addNewList: (listName: string) => string;
  deleteList: (listId: string) => void;
  renameList: (listId: string, newName: string) => void;
}

export interface Loading {
  id: "loading";
}

export type RFPSInList = SingleList | MultipleLists | Loading;

const selectList = (
  lists: Record<string, ShortList>,
  listId?: string,
  favoriteListId: string | null = null,
  forceAll: boolean = false
) => {
  if (forceAll) {
    window.history.replaceState({}, "", "/all-lists/");
    return null;
  }

  if (Object.keys(lists).length === 1) {
    window.history.replaceState({}, "", "/lists/");
    return {
      list: Object.values(lists)[0],
      id: Object.keys(lists)[0],
      hasOtherLists: false,
    };
  } else if (listId && lists[listId]) {
    window.history.replaceState({}, "", `/lists/${listId}/`);
    return { list: lists[listId], id: listId, hasOtherLists: true };
  } else if (favoriteListId && lists[favoriteListId]) {
    window.history.replaceState({}, "", "/lists/");
    return {
      list: lists[favoriteListId],
      id: favoriteListId,
      hasOtherLists: true,
    };
  } else {
    window.history.replaceState({}, "", "/lists/");
    return null;
  }
};

/**
 * Get all of the RFPs in a specific list
 *
 * This abstracts away the complexity of merging the RFP id list in Doc Hub
 * with the RFP details in Core. In particular this holds the RFP info in memory
 * and only fetches RFPs when new RFPs are added to the list (presumably by another
 * user)
 */
const useRFPsInList = (
  listId?: string,
  forceAll: boolean = false
): RFPSInList => {
  const {
    lists,
    connectionStatus,
    deleteList,
    removeRFPFromList,
    renameList,
    addNewList,
  } = useShortLists();
  const { favoriteListId } = useAuthenticatedUser();
  const selectedList = selectList(lists, listId, favoriteListId, forceAll);
  const apiClient = useApiClient();

  // We store each RFP in a map to avoid refetching the same RFP multiple times
  const [rfpInfo, setRFPInfo] = useState<Record<string, RFPSearch>>({});
  const [orderedRfps, setOrderedRfps] = useState<RFPSearch[] | null>(null);

  useEffect(() => {
    // Make sure we have all the necessary RFPs in rfpInfo
    if (!selectedList) {
      return;
    }

    const newRfps = Object.keys(selectedList.list.rfpIds).filter(
      (id) => !rfpInfo[id]
    );
    if (newRfps.length === 0) {
      return;
    }

    const fetchRFPs = async () => {
      const response = await apiClient.rfp.rfpRfpMultiListCreate({
        rfp_ids: newRfps,
      });
      const now = new Date();

      const newRFPInfo = { ...rfpInfo };
      // Insert the new RFPs into the map
      for (const rfp of response.data) {
        if (new Date(rfp.due_date) < now) {
          // This RFP is past due, remove it from the list
          removeRFPFromList(selectedList.id, rfp.id);
        } else {
          newRFPInfo[rfp.id] = rfp;
        }
      }

      setRFPInfo(newRFPInfo);
    };

    fetchRFPs();
  }, [apiClient.rfp, selectedList, orderedRfps, rfpInfo, removeRFPFromList]);

  useEffect(() => {
    // Keep orderedRfps up to date based on the RFP ids in selectedList
    // and the info in rfpInfo

    if (!selectedList) {
      setOrderedRfps(null);
      return;
    }

    setOrderedRfps((oldOrderedRfps) => {
      // Insert the new RFPs into the list ordered by due date (most recent first)

      // Get the set of new RFP idsj
      const remainingNewRfps = new Set(Object.keys(selectedList.list.rfpIds));
      for (const rfp of oldOrderedRfps ?? []) {
        remainingNewRfps.delete(rfp.id);
      }

      // Now we can iterate through the old list and add the new RFPs in the correct order
      let newOrderedRfps = [];
      let didChange = false;
      for (const nextRFP of oldOrderedRfps ?? []) {
        // Check if any new RFPs are due before this one
        for (const newId of Array.from(remainingNewRfps)) {
          const info = rfpInfo[newId];
          if (info && info.due_date < nextRFP.due_date) {
            // This new RFP is due before the next RFP in the list
            // Add it to the list
            newOrderedRfps.push(rfpInfo[newId]);
            remainingNewRfps.delete(newId);
            didChange = true;
          }
        }
        // Only add the existing RFP if it is still in the list
        if (selectedList.list.rfpIds[nextRFP.id]) {
          newOrderedRfps.push(nextRFP);
        } else {
          // This RFP is no longer in the list, don't add it back
          didChange = true;
        }
      }

      // Add the remaining new RFPs
      for (const newId of remainingNewRfps) {
        if (rfpInfo[newId]) {
          newOrderedRfps.push(rfpInfo[newId]);
          didChange = true;
        }
      }

      if (!didChange) {
        return oldOrderedRfps;
      }

      return newOrderedRfps;
    });
  }, [rfpInfo, selectedList]);

  if (connectionStatus === "connecting") {
    return {
      id: "loading",
    };
  }

  if (!forceAll && selectedList) {
    return {
      id: "single",
      listId: selectedList.id,
      listName: selectedList.list.name,
      rfps: orderedRfps ?? [],
      hasOtherLists: selectedList.hasOtherLists,
      deleteList: () => deleteList(selectedList.id),
      renameList: (newName: string) => renameList(selectedList.id, newName),
      addNewList,
    };
  } else {
    return {
      id: "multiple",
      lists: lists,
      addNewList,
      deleteList,
      renameList,
    };
  }
};

export default useRFPsInList;
