import { RFPList, RFPDetail } from "api/Api";

export interface RFP {
  id: string;
  name: string;
  issuingOrg: string | null;
  created: string;
  processingStatus: string;
  invalidReason: string | null;
  nextStep: string | null;
  foiaId: number | null;
  hasPDF: boolean;
  hasMarkdown: boolean;
  orgs: string[];
  categories: string[];
  testDataId: number | null;
}

export interface RFPCategory {
  name: string;
  displayName: string;
}

export interface RFPDetails {
  id: string;
  name: string;
  issuingOrg: string | null;
  created: string;
  categories: RFPCategory[];
}

export const rfpFromApiList = (rfp: RFPList): RFP => {
  return {
    id: rfp.id!,
    name: rfp.display_name!,
    issuingOrg: rfp.issuing_org ?? "unknown",
    created: rfp.created!,
    processingStatus: rfp.processing_status ?? "unknown",
    invalidReason: rfp.invalid_reason ?? null,
    nextStep: rfp.next_step ?? null,
    hasMarkdown: rfp.has_markdown!,
    hasPDF: rfp.is_uploaded!,
    orgs: rfp.orgs ?? [],
    categories: rfp.categories ?? [],
    foiaId: rfp.foia_request_job_id ?? null,
    testDataId: rfp.test_data_id ?? null,
  };
};

export const rfpFromApiDetails = (rfp: RFPDetail): RFPDetails => {
  return {
    id: rfp.id!,
    name: rfp.display_name,
    issuingOrg: rfp.issuing_org ?? null,
    created: rfp.created!,
    categories: (rfp.categories ?? []).map((c) => {
      return {
        name: c.name,
        displayName: c.display_name,
      };
    }),
  };
};
