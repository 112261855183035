import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { ItemDetails } from "api/Api";
import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import SavedStateIndicator, {
  SaveState,
} from "components/common/forms/SavedStatusIndicator";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "components/EditorView/Menus/DropdownMenu";
import {
  singularNameFromCategory,
  VALID_ITEM_KINDS,
} from "hooks/useContentLibrary";
import useFetchedData from "hooks/useFetchedData";
import { debounce } from "lodash";
import { useConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useRef, useState } from "react";
import { message_from_exception } from "utils";
import ProjectDetailsView from "./ProjectDetailsView";
import PersonDetailsView from "./PeopleDetailsView";
import OverviewDetailsView from "./OverviewDetailsView";
import CoverLetterDetailsView from "./CoverLetterDetailsView";
import SubcontractorDetailsView from "./SubcontractorDetailsView";
import ApproachDetailsView from "./ApproachDetailsView";
import Spacer from "components/common/containers/Spacer";

interface BaseItemDetailsViewProps {
  id: string;
  proposalId: string | null;
  onChanged: (details: ItemDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  deleteItem: () => Promise<void>;
  // This is a choose button that will be shown if the item
  // is selected. Used in circumstances like choosing a base
  // item for a section.
  chooseItem?: () => Promise<void>;
}

const BaseItemDetailsView = ({
  id,
  proposalId,
  onChanged,
  onSaveStateChanged,
  deleteItem: remove,
  chooseItem,
}: BaseItemDetailsViewProps) => {
  const apiClient = useApiClient();

  const [saveState, doSetSaveState] = useState<SaveState>("unchanged");
  const [saveError, setSaveError] = useState<string | null>(null);
  const confirm = useConfirm();

  const [details, doSetDetails, { error: loadingError }] =
    useFetchedData<ItemDetails>(async () => {
      const result = await apiClient.rfp.rfpContentLibraryRead(id, {
        // @ts-ignore
        query: { proposal_id: proposalId },
      });
      return result.data;
    }, [id]);

  const saveItem = async (details: ItemDetails) => {
    await apiClient.rfp.rfpContentLibraryUpdate(details.id!, details as any, {
      // @ts-ignore
      query: { proposal_id: proposalId },
    });
  };

  const setSaveState = (state: SaveState) => {
    doSetSaveState(state);
    onSaveStateChanged?.(state);
  };

  const debouncedSave = useRef(
    debounce(async (details: ItemDetails) => {
      try {
        await saveItem(details);
        setSaveState("saved");
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }, 500)
  );

  const setDetails = (details: ItemDetails) => {
    doSetDetails(details);
    setSaveState("saving");
    onChanged(details);
    debouncedSave.current(details);
  };

  const tryAgain = () => {
    if (details) {
      setSaveState("saving");
      setSaveError(null);
      debouncedSave.current(details);
    }
  };

  const handleDelete = async () => {
    if (!details) return;
    const result = await confirm("Are you sure you want to delete this?", {
      yesText: "Delete",
      yesDestructive: true,
    });
    if (result) {
      try {
        await remove();
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }
  };

  const handleChoose = async () => {
    if (!details) return;
    if (!chooseItem) return;
    try {
      await chooseItem();
    } catch (e) {
      setSaveState("error");
      setSaveError(message_from_exception(e));
    }
  };

  return (
    <AsyncLoadedDiv
      value={details}
      error={!!loadingError ? message_from_exception(loadingError) : undefined}
      className="grow p-lg pt-0 overflow-y-auto flex"
      whileLoaded={(details) => (
        <Rows className="grow">
          <Columns className="items-center sticky top-0 pt-lg pb-md bg-background">
            <h1 className="text-lg font-semibold grow shrink-0">Details</h1>
            {saveError && (
              <p className="grow text-destructive text-right pr-md">
                {saveError}
              </p>
            )}
            <SavedStateIndicator state={saveState} tryAgain={tryAgain} />
          </Columns>
          <Rows className="grow gap-md justify-start">
            <Scrollable>
              <Rows className="grow gap-md justify-start">
                <ItemDetailsContent details={details} setDetails={setDetails} />
              </Rows>
            </Scrollable>
            <Columns className="shrink-0 gap-lg">
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="border border-primary text-primary rounded-sm px-sm py-xs">
                    Change Type
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {VALID_ITEM_KINDS.map((kind) => (
                    <DropdownMenuItem
                      onSelect={() =>
                        setDetails({ ...details, kind: kind as any })
                      }
                    >
                      {singularNameFromCategory(kind)}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
              <Spacer />
              <Button
                text="Delete"
                icon="trash"
                variant="destructive"
                onClick={handleDelete}
              />
              {chooseItem && (
                <Button
                  text="Choose"
                  icon="check"
                  variant="solid"
                  onClick={handleChoose}
                />
              )}
            </Columns>
          </Rows>
        </Rows>
      )}
    />
  );
};

const ItemDetailsContent = ({
  details,
  setDetails,
}: {
  details: ItemDetails;
  setDetails: (details: ItemDetails) => void;
}) => {
  switch (details.kind) {
    case "project":
      return (
        <ProjectDetailsView
          key={details.id}
          details={details}
          setDetails={setDetails}
        />
      );
    case "person":
      return (
        <PersonDetailsView
          key={details.id}
          details={details}
          setDetails={setDetails}
        />
      );
    case "overview":
      return (
        <OverviewDetailsView
          key={details.id}
          details={details}
          setDetails={setDetails}
        />
      );
    case "cover_letter":
      return (
        <CoverLetterDetailsView
          key={details.id}
          details={details}
          setDetails={setDetails}
        />
      );
    case "subcontractor":
      return (
        <SubcontractorDetailsView
          key={details.id}
          details={details}
          setDetails={setDetails}
        />
      );
    case "approach":
      return (
        <ApproachDetailsView
          key={details.id}
          details={details}
          setDetails={setDetails}
        />
      );
  }
};

export default BaseItemDetailsView;
