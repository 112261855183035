import Button from "components/common/Button";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useEffect, useRef, useState } from "react";
import { RFPOrgComparison } from "types/RFPOrgComparison";
import RFPComparisonOptionsView from "./RFPComparisonOptionsView";
import { useOverlayContext } from "components/common/containers/overlays/OverlayContextProvider";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { cn, format_absolute_date } from "lib/utils";
import { isOnMobile } from "utils";
import LoadableView from "components/common/containers/LoadableView";
import { usePostHog } from "posthog-js/react";
import { useChoose } from "providers/AlertProvider";
import Spacer from "components/common/containers/Spacer";

interface RFPDetailsViewProps {
  comparison: RFPOrgComparison;
  onDecline?: () => void;
}

const RFPDetailsView: FC<RFPDetailsViewProps> = ({ comparison, onDecline }) => {
  const apiClient = useApiClient();
  const { dismiss } = useOverlayContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<"details" | "fullRFP">(
    "details"
  );
  const posthog = usePostHog();
  const [pdfUrl, setPdfUrl] = useState<string | null | undefined>(undefined);
  const pdfUrlRef = useRef<string | null | undefined>(undefined);
  const choose = useChoose();
  const [isDownloading, setIsDownloading] = useState(false);
  useEffect(() => {
    setPdfUrl(undefined);
    const fetchPdfUrl = async () => {
      const response = await apiClient.rfp.rfpRfpRead(comparison.rfpId);
      setPdfUrl(response.data.view_url);
      pdfUrlRef.current = response.data.view_url;
    };
    fetchPdfUrl();
  }, [apiClient.rfp, comparison.rfpId]);

  const handleDecline = () => {
    dismiss?.();
    onDecline?.();
  };

  const handleViewFullRFP = async () => {
    if (isOnMobile()) {
      // Open the full RFP in a new tab since the PDF viewer doesn't work well on mobile
      setIsLoading(true);
      const response = await apiClient.rfp.rfpRfpRead(comparison.rfpId);
      posthog.capture("rfp_viewed_full", {
        rfp_slug: response.data.slug ?? "unknown",
      });
      setIsLoading(false);
      window.open(response.data.view_url, "_blank");
      return;
    }
    setSelectedTab("fullRFP");
  };

  const handleDownload = async () => {
    const pdfUrl = pdfUrlRef.current;
    if (pdfUrl === null) {
      setIsDownloading(false);
      // If the user hasn't subscribed, show a choose dialog
      const result = await choose("You need to subscribe to view full RFPs.", {
        dismissId: "cancel",
        choices: [
          {
            id: "cancel",
            text: "Cancel",
            variant: "outline",
          },
          {
            id: "book-demo",
            text: "Book Demo",
            variant: "solid",
          },
        ],
      });
      if (result === "book-demo") {
        window.open("https://odo.do/book-demo/?source=full-rfp-app", "_blank");
      }
      return;
    }

    if (pdfUrl === undefined) {
      // If we haven't finished loading the PDF, try again after a short delay
      setIsDownloading(true);
      setTimeout(handleDownload, 500);
      return;
    }

    setIsDownloading(false);
    window.open(pdfUrl, "_blank");
  };

  return (
    <LoadableView
      isLoading={isLoading}
      className="grow flex flex-col p-md relative w-full -t-md overflow-hidden"
    >
      <div className="flex border-b">
        <Button
          variant="navigation"
          text="Details"
          disabled={selectedTab === "details"}
          onClick={() => setSelectedTab("details")}
        />
        <Button
          variant="navigation"
          text="Full RFP"
          disabled={selectedTab === "fullRFP"}
          onClick={handleViewFullRFP}
        />
      </div>
      <div className="grow flex overflow-y-auto border-b">
        <DetailsView
          comparison={comparison}
          pdfUrl={pdfUrl}
          hidden={selectedTab !== "details"}
        />
        <FullRFPView
          comparison={comparison}
          pdfUrl={pdfUrl}
          hidden={selectedTab !== "fullRFP"}
        />
      </div>

      <div className="flex gap-md h-2xl mt-sm shrink-0">
        <Button
          text="Download"
          icon="download"
          variant="outline"
          onClick={handleDownload}
          isLoading={isDownloading}
        />
        <Spacer />
        {onDecline && (
          <RFPComparisonOptionsView
            comparison={comparison}
            hideRFP={handleDecline}
          />
        )}
      </div>
    </LoadableView>
  );
};

interface PaneViewProps {
  comparison: RFPOrgComparison;
  pdfUrl: string | null | undefined;
  hidden: boolean;
}

const DetailsView: FC<PaneViewProps> = ({ comparison, hidden, pdfUrl }) => {
  if (hidden) {
    return null;
  }
  return (
    <div className="flex flex-col gap-md t-md max-w-[800px]">
      <div className="bg-background-secondary rounded-md p-md flex gap-lg">
        <LabeledFormInput label="Proposal Due">
          {comparison.dueDate ? format_absolute_date(comparison.dueDate) : "-"}
        </LabeledFormInput>
        <LabeledFormInput label="Budget">
          {comparison.budget ? String(comparison.budget) : "-"}
        </LabeledFormInput>
        <LabeledFormInput label="Location">
          {comparison.location ? String(comparison.location) : "-"}
        </LabeledFormInput>
      </div>
      <LabeledFormInput label="Buyer">
        <p>{String(comparison.supplier)}</p>
      </LabeledFormInput>
      <LabeledFormInput label="Brief Description">
        <p>{comparison.description}</p>
      </LabeledFormInput>
      <LabeledFormInput label="Key Dates">
        {comparison.keyDates.length > 0 ? (
          <ul className="list-disc ml-lg b-md">
            {comparison.keyDates
              .filter((date) => !!date.date)
              .sort((a, b) => a.date.localeCompare(b.date))
              .map((date, index) => (
                <li key={index}>
                  {format_absolute_date(date.date)} - {date.name}
                </li>
              ))}
          </ul>
        ) : (
          "Refer to RFP"
        )}
      </LabeledFormInput>
      <LabeledFormInput label="Minimum Qualifications">
        {comparison.minimumQualifications.length > 0 ? (
          <ul className="list-disc ml-lg b-md">
            {comparison.minimumQualifications.map((qual, index) => (
              <li key={index}>{qual}</li>
            ))}
          </ul>
        ) : (
          "Refer to RFP"
        )}
      </LabeledFormInput>
      <LabeledFormInput label="Task Summary">
        {comparison.tasks.length > 0 ? (
          <ul className="list-disc ml-lg b-md">
            {comparison.tasks.map((task, index) => (
              <li key={index}>{task}</li>
            ))}
          </ul>
        ) : (
          "Refer to RFP"
        )}
      </LabeledFormInput>
    </div>
  );
};

const FullRFPView: FC<PaneViewProps> = ({ comparison, hidden, pdfUrl }) => {
  if (hidden) {
    return null;
  }
  return (
    <div className="grow bg-background-selected flex flex-col relative">
      <div
        className={cn(
          "absolute inset-0 flex items-center justify-center",
          "transition-opacity duration-300",
          !!pdfUrl ? "opacity-100" : "opacity-0"
        )}
      >
        <a
          className="underline text-primary"
          href={pdfUrl ?? undefined}
          target="_blank"
          rel="noreferrer"
        >
          Download full RFP
        </a>
      </div>
      {!!pdfUrl && (
        <iframe src={pdfUrl} className="w-full grow z-[1]" title="RFP PDF" />
      )}
      {pdfUrl === null && (
        <div className="absolute inset-0 flex flex-col items-center justify-center gap-md">
          <p className="text-lg">You need to subscribe to view full RFPs.</p>
          <Button
            text="Book Demo"
            variant="solid"
            onClick={() => {
              window.open(
                "https://odo.do/book-demo/?source=full-rfp-app",
                "_blank"
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RFPDetailsView;
