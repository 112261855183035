import { cn } from "lib/utils";
import {
  FC,
  HTMLProps,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Spinner from "../Spinner";
import { isPagesPaginatedData, PaginatedData } from "hooks/usePaginatedData";
import { message_from_exception } from "utils";
import Input from "../forms/Input";
import { debounce } from "lodash";
import Toggle from "../forms/Toggle";
import Rows from "./Rows";
import CenteredContainer from "./CenteredContainer";
import Columns from "./Columns";
import ErrorView from "./ErrorView";
import React from "react";
import Button from "../Button";
import { Link } from "react-router-dom";

type PaginatedTableVariant = "default" | "without-padding";

export interface Column {
  name?: string;
  size?: "auto" | "min" | number;
  center?: boolean;
}

export interface CellProps extends HTMLProps<HTMLElement> {
  center?: boolean;
  cellRef?: React.LegacyRef<HTMLElement>;
}

interface RowProps {
  children?: React.ReactNode;
}

interface PaginatedTableViewProps<T> {
  results: T[] | null;
  paginatedData: PaginatedData;
  columns: Column[] | number;
  rowHeight?: number;
  rowSeparators?: boolean;
  renderRow: (
    item: T,
    Cell: FC<CellProps>,
    Row: FC<RowProps>,
    index: number
  ) => ReactElement<RowProps>;
  onSelect?: (item: T) => void;
  selectNavigate?: string | ((item: T) => string);
  searchMode?: "none" | "search" | "filter";
  // Customize the search bar element
  renderSearchBarElement?: (standard: ReactElement) => ReactElement;
  className?: string;
  scrollableClassName?: string;
  isSelected?: (item: T) => boolean;
  noResultsText?: string;
  variant?: PaginatedTableVariant;
  renderNoResultsFound?: () => ReactElement;
  mode?: "infinite" | "pages";
}

interface SearchBarProps {
  searchMode?: "none" | "search" | "filter";
  pendingSearch: string;
  setPendingSearch: (value: string) => void;
  filters?: Record<
    string,
    { value: boolean; setValue: (value: boolean) => void }
  >;
  variant?: PaginatedTableVariant;
  renderSearchBarElement?: (standard: ReactElement) => ReactElement;
}

const SearchBar: FC<SearchBarProps> = ({
  searchMode,
  pendingSearch,
  setPendingSearch,
  filters,
  variant,
  renderSearchBarElement,
}) => {
  if (searchMode === "none" && !filters) return null;

  const standardContent = (
    <>
      {searchMode !== "none" && (
        <Input
          icon={searchMode === "search" ? "magnifying-glass" : "filter"}
          placeholder={searchMode === "search" ? "Search" : "Filter"}
          className="m-sm sticky"
          variant="accent"
          value={pendingSearch}
          valueClearable={true}
          onChange={(e) => {
            setPendingSearch(e.target.value);
          }}
        />
      )}
      {Object.entries(filters ?? {}).map(([name, { value, setValue }]) => (
        <Toggle
          key={name}
          text={name}
          on={value}
          onToggle={(on) => {
            setValue(on);
          }}
        />
      ))}
    </>
  );

  return (
    <Columns
      className={cn(
        "gap-md items-center shrink-0 overflow-visible",
        variant === "without-padding" && "border-b"
      )}
    >
      {renderSearchBarElement
        ? renderSearchBarElement(standardContent)
        : standardContent}
    </Columns>
  );
};

const PaginatedTableView = <T,>({
  results,
  paginatedData,
  renderRow,
  rowSeparators,
  onSelect,
  columns,
  rowHeight,
  searchMode = "none",
  className,
  scrollableClassName,
  isSelected,
  renderNoResultsFound,
  selectNavigate,
  renderSearchBarElement,
  noResultsText = "No results",
  variant = "default",
}: PaginatedTableViewProps<T>) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const mode = paginatedData.mode;
  const { error, setSearch, loadNext, loadPrevious } = paginatedData;

  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [pendingSearch, setPendingSearch] = useState<string>("");
  const debouncedSearch = useRef(
    debounce((search: string) => {
      setSearch(search);
    }, 500)
  );

  useEffect(() => {
    debouncedSearch.current(pendingSearch);
  }, [debouncedSearch, pendingSearch, setSearch]);

  useEffect(() => {
    if (mode !== "infinite") return;

    const scrollable = scrollableRef.current;
    if (scrollable) {
      const checkScoll = (div: HTMLDivElement) => {
        const scrollFromTop = div.scrollTop;
        if (scrollFromTop < 200 && typeof loadPrevious === "function") {
          loadPrevious();
        }

        const scrollFromBottom =
          div.scrollHeight - div.clientHeight - scrollFromTop;

        if (scrollFromBottom < 200 && typeof loadNext === "function") {
          loadNext();
        }
      };

      const handleScroll = (e: any) => {
        checkScoll(e.target);
      };
      checkScoll(scrollable);

      scrollable.addEventListener("scroll", handleScroll);
      return () => {
        scrollable.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loadNext, loadPrevious, scrollableRef]);

  let body: ReactElement;

  if (!!error) {
    body = (
      <Rows className={className}>
        <ErrorView title="Error Loading" error={error} />
      </Rows>
    );
  } else if (results === null) {
    body = (
      <CenteredContainer>
        <Spinner text="Loading..." />
      </CenteredContainer>
    );
  } else if (paginatedData.noResultsFound && renderNoResultsFound) {
    body = renderNoResultsFound();
  } else {
    body = (
      <PaginatedTableContent
        className={className}
        paginatedData={paginatedData}
        variant={variant}
        pendingSearch={pendingSearch}
        setPendingSearch={setPendingSearch}
        results={results}
        noResultsText={noResultsText}
        scrollableClassName={scrollableClassName}
        scrollableRef={scrollableRef}
        columns={columns}
        mode={mode}
        loadPrevious={loadPrevious}
        loadNext={loadNext}
        error={error}
        renderRow={renderRow}
        onSelect={onSelect}
        selectNavigate={selectNavigate}
        setHoveredRow={setHoveredRow}
        hoveredRow={hoveredRow}
        rowHeight={rowHeight}
        isSelected={isSelected}
        rowSeparators={rowSeparators}
        renderSearchBarElement={renderSearchBarElement}
      />
    );
  }

  return (
    <Rows className={className}>
      <SearchBar
        searchMode={searchMode}
        pendingSearch={pendingSearch}
        setPendingSearch={setPendingSearch}
        filters={paginatedData.filters ?? {}}
        variant={variant}
        renderSearchBarElement={renderSearchBarElement}
      />
      {body}
    </Rows>
  );
};

/**
 * A special row component that takes a key and children
 *
 * It applies the key to each child and then returns the children
 */
const Row = ({ children }: RowProps) => {
  return <>{children}</>;
};

const wrapCell = ({
  className: parentClassName,
  selectNavigate,
  ...parentProps
}: HTMLProps<HTMLElement> & {
  ref?: React.LegacyRef<HTMLElement>;
  selectNavigate?: string;
}) => {
  const Cell: FC<CellProps> = ({
    className,
    center,
    children,
    cellRef,
    ...childProps
  }) => {
    const commonProps: HTMLProps<HTMLElement> = {
      ...parentProps,
      ...childProps,
      ref: cellRef,
      className: cn(
        "px-2m py-sm z-auto flex items-center bg-background",
        center && "justify-center",
        className,
        parentClassName
      ),
    };
    if (selectNavigate) {
      return (
        <Link to={selectNavigate} {...(commonProps as any)}>
          {children}
        </Link>
      );
    }
    return <div {...(commonProps as any)}>{children}</div>;
  };

  return Cell;
};

const HeaderCell = wrapCell({
  className:
    "text-secondary text-sm border-b pb-sm font-medium sticky top-0 bg-background",
});

const gridTemplateColumnsFromColumns = (columns: Column[] | number) => {
  if (typeof columns === "number") {
    return `repeat(${columns}, auto)`;
  }
  return columns
    .map((col) => {
      switch (col.size ?? "auto") {
        case "auto":
          return "auto";
        case "min":
          return "fit-content(100%)";
        default:
          return `${col.size}px`;
      }
    })
    .join(" ");
};

interface PaginatedTableContentProps {
  className?: string;
  renderSearchBarElement?: (standard: ReactElement) => ReactElement;
  paginatedData: PaginatedData;
  variant?: PaginatedTableVariant;
  pendingSearch: string;
  setPendingSearch: (value: string) => void;
  results: any[];
  noResultsText: string;
  scrollableClassName?: string;
  scrollableRef: React.RefObject<HTMLDivElement>;
  columns: Column[] | number;
  mode?: "infinite" | "pages";
  loadPrevious: (() => void) | string | null;
  loadNext: (() => void) | string | null;
  error?: any;
  renderRow: (
    value: any,
    Cell: FC<CellProps>,
    Row: FC<RowProps>,
    index: number
  ) => ReactElement;
  onSelect?: (value: any) => void;
  selectNavigate?: string | ((value: any) => string);
  setHoveredRow: (value: number | null) => void;
  hoveredRow: number | null;
  rowHeight?: number;
  isSelected?: (value: any) => boolean;
  rowSeparators?: boolean;
}

const PaginatedTableContent = ({
  className,
  paginatedData,
  results,
  noResultsText,
  scrollableClassName,
  scrollableRef,
  columns,
  mode,
  loadPrevious,
  loadNext,
  error,
  renderRow,
  onSelect,
  selectNavigate,
  setHoveredRow,
  hoveredRow,
  rowHeight,
  isSelected,
  rowSeparators,
}: PaginatedTableContentProps) => {
  const rows = useMemo(() => {
    if (results.length === 0) {
      return (
        <CenteredContainer className="col-span-full text-secondary text-center py-2m grow">
          {noResultsText}
        </CenteredContainer>
      );
    }

    return (
      <Rows
        className={cn("overflow-auto", scrollableClassName)}
        ref={scrollableRef}
      >
        <div
          className="grid"
          style={{
            gridTemplateColumns: gridTemplateColumnsFromColumns(columns),
          }}
        >
          {Array.isArray(columns) &&
            columns.find((col) => col.name) &&
            columns.map((col) => (
              <HeaderCell
                key={col.name}
                className={col.center === true ? "justify-center" : ""}
              >
                {col.name}
              </HeaderCell>
            ))}
          {mode === "infinite" && typeof loadPrevious === "function" && (
            <div className="col-span-full">
              <Spinner className="mx-auto y-md" />
            </div>
          )}
          {!!error && (
            <div className="ol-span-full text-destructive">
              {message_from_exception(error)}
            </div>
          )}
          {results.map((value, i) => {
            return renderRow(
              value,
              wrapCell({
                onClick: onSelect ? () => onSelect(value) : undefined,
                selectNavigate:
                  typeof selectNavigate === "function"
                    ? selectNavigate(value)
                    : selectNavigate,
                onMouseEnter: onSelect ? () => setHoveredRow(i) : undefined,
                onMouseLeave: onSelect ? () => setHoveredRow(null) : undefined,
                style: {
                  height: rowHeight ? `${rowHeight}px` : undefined,
                },
                className: cn(
                  hoveredRow === i || !!isSelected?.(value)
                    ? "bg-background-selected"
                    : "",
                  onSelect || selectNavigate ? "cursor-pointer" : "",
                  rowSeparators && i !== results.length - 1 ? "border-b" : ""
                ),
              }),
              Row,
              i
            );
          })}
          {mode === "infinite" && typeof loadNext === "function" && (
            <div className="col-span-full" onClick={loadNext}>
              <Spinner className="mx-auto y-md" />
            </div>
          )}
          {mode === "infinite" && typeof loadNext === "string" && (
            <div className="col-span-full text-destructive text-center py-2m">
              Error: {loadNext}
            </div>
          )}
        </div>
      </Rows>
    );
  }, [
    results,
    scrollableClassName,
    scrollableRef,
    columns,
    mode,
    loadPrevious,
    error,
    loadNext,
    noResultsText,
    renderRow,
    onSelect,
    selectNavigate,
    rowHeight,
    hoveredRow,
    isSelected,
    rowSeparators,
    setHoveredRow,
  ]);

  return (
    <Rows className={className}>
      {rows}
      {isPagesPaginatedData(paginatedData) && (
        <Columns className="justify-center items-center shrink-0">
          <Button
            icon="chevron-left"
            disabled={typeof loadPrevious !== "function"}
            onClick={() => {
              if (typeof loadPrevious === "function") {
                loadPrevious();
              }
            }}
          />
          <div className="col-span-full text-secondary text-center py-2m min-w-[100px]">
            Page {paginatedData.currentPage} of {paginatedData.pageCount}
          </div>
          <Button
            disabled={typeof loadNext !== "function"}
            icon="chevron-right"
            onClick={() => {
              if (typeof loadNext === "function") {
                loadNext();
              }
            }}
          />
        </Columns>
      )}
    </Rows>
  );
};

export default PaginatedTableView;
