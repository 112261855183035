import { useState } from "react";
import { useApiClient } from "providers/ApiClientProvider";
import { Prompt } from "api/Api";
import AddPromptForm from "components/PromptRefinery/AddPromptForm";
import usePaginatedData from "hooks/usePaginatedData";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import Overlay from "components/common/containers/overlays/Overlay";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { format_date } from "lib/utils";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import { useTextAreaConfirm } from "providers/AlertProvider";
import { odoToast } from "lib/odoToast";

const PromptsRoute = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [prompts, setPrompts, paginatedData] = usePaginatedData({
    endpoint: apiClient.promptRefinery.promptRefineryPromptsList,
    map: (prompt): Prompt & { id: number } => {
      return {
        ...prompt,
        id: prompt.id!,
      };
    },
  });
  const [addingPrompt, setAddingPrompt] = useState(false);
  const textConfirm = useTextAreaConfirm();

  const handleToggleUsed = async (prompt: Prompt) => {
    if (!prompt.id) return;
    setPrompts((prev) => {
      if (!prev) return prev;
      return prev.map((p) =>
        p.id === prompt.id ? { ...p, used_in_product: !p.used_in_product } : p
      );
    });
    try {
      await apiClient.promptRefinery.promptRefineryPromptsPartialUpdate(
        prompt.id,
        {
          used_in_product: !prompt.used_in_product,
        }
      );
      prompt.used_in_product = !prompt.used_in_product;
    } catch (error) {
      odoToast.caughtError(error, "Updating Prompt Used Status");
      setPrompts((prev) => {
        if (!prev) return prev;
        return prev.map((p) =>
          p.id === prompt.id
            ? { ...p, used_in_product: prompt.used_in_product }
            : p
        );
      });
    }
  };

  const handleEditName = async (prompt: Prompt) => {
    if (!prompt.id) return;
    const { id: choice, text: newName } = await textConfirm(
      "Update Prompt Name",
      {
        initialText: prompt.name,
        dismissId: "cancel",
        buttons: [
          { id: "cancel", text: "Cancel" },
          {
            id: "update",
            text: "Update",
            variant: "solid",
          },
        ],
      }
    );
    if (choice === "cancel") return;
    setPrompts((prev) => {
      if (!prev) return prev;
      return prev.map((p) =>
        p.id === prompt.id ? { ...p, name: newName } : p
      );
    });
    try {
      await apiClient.promptRefinery.promptRefineryPromptsPartialUpdate(
        prompt.id,
        {
          name: newName,
        }
      );
    } catch (error) {
      odoToast.caughtError(error, "Updating Prompt Name");
      setPrompts((prev) => {
        if (!prev) return prev;
        return prev.map((p) =>
          p.id === prompt.id ? { ...p, name: prompt.name } : p
        );
      });
    }
  };

  return (
    <Rows className="p-lg w-full gap-md">
      <Columns className="items-center gap-md shrink-0">
        <h1 className="text-2xl font-semibold">Prompts</h1>
        <Button
          icon="plus"
          variant="solid"
          onClick={() => setAddingPrompt(true)}
        />
      </Columns>
      <PaginatedTableView
        searchMode="search"
        results={prompts}
        paginatedData={paginatedData}
        columns={[
          { name: "Used" },
          { name: "Name" },
          { name: "Description" },
          { name: "Created" },
        ]}
        onSelect={(prompt) => {
          navigate(`/admin/prompts/${prompt.id}`);
        }}
        renderRow={(prompt, Cell, Row) => {
          return (
            <Row key={prompt.id}>
              <Cell center={true}>
                <Button
                  icon="star"
                  iconVariant="solid"
                  onClick={() => handleToggleUsed(prompt)}
                  className={
                    prompt.used_in_product ? "opacity-100" : "opacity-10"
                  }
                />
              </Cell>
              <Cell>
                <Columns className="gap-md">
                  {prompt.name}
                  <Button icon="pen" onClick={() => handleEditName(prompt)} />
                </Columns>
              </Cell>
              <Cell>{prompt.description}</Cell>
              <Cell>{format_date(prompt.created!)}</Cell>
            </Row>
          );
        }}
      />
      <Overlay
        title="Add Prompt"
        maxWidth={500}
        open={addingPrompt}
        onClose={() => setAddingPrompt(false)}
      >
        <AddPromptForm />
      </Overlay>
    </Rows>
  );
};

export default PromptsRoute;
