import { useApiClient } from "providers/ApiClientProvider";
import { message_from_exception } from "utils";
import { format_date } from "lib/utils";
import Button from "components/common/Button";
import usePaginatedData from "hooks/usePaginatedData";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { useRef, useState } from "react";
import Overlay from "components/common/containers/overlays/Overlay";
import Pill from "components/common/Pill";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import useTriggerDownload from "hooks/useTriggerDownload";
import RFPDetailsOverlay from "./RFPDetailsOverlay";
import FileInput, { FileInputRef } from "components/common/forms/FileInput";
import AddRFPForm from "components/rfps/AddRFPForm";
import { RFP, rfpFromApiList } from "types/RFP";
import { copyToClipboard } from "lib/clipboard";
import { odoToast } from "lib/odoToast";

const RFPsRoute = () => {
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const [addingRFP, setAddingRFP] = useState(false);
  const [addingCSV, setAddingCSV] = useState(false);
  const [viewingRFP, setViewingRFP] = useState<RFP | null>(null);
  const [rfps, setRfps, paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpRfpListList,
    mode: "pages",
    filters: [
      { key: "has_proposals", name: "Only with Proposal", default: false },
      { key: "is_test_data", name: "Only Test Data", default: false },
    ],
    map: rfpFromApiList,
  });
  const { refresh } = paginatedData;

  const getDetials = async (rfpId: string) => {
    try {
      const response = await apiClient.rfp.rfpRfpRead(rfpId);
      return response.data;
    } catch (e) {
      odoToast.caughtError(e, "Getting RFP Details");
      return null;
    }
  };

  const handleViewPDF = async (rfpId: string) => {
    const details = await getDetials(rfpId);
    if (!details) {
      return;
    }

    if (!details.view_url) {
      odoToast.error({
        title: "No PDF uploaded yet",
        text: "Either the processing is stuck or the RFP is still processing",
      });
      return;
    }

    triggerDownload(details.view_url, "tab");
  };

  const handleViewMarkdown = async (rfpId: string) => {
    const details = await getDetials(rfpId);
    if (!details) {
      return;
    }
    if (!details.markdown_url) {
      odoToast.error({
        title: "No markdown generated yet",
        text: "This will take a few minutes to complete",
      });
      return;
    }
    triggerDownload(details.markdown_url);
  };

  const triggerFoia = async (rfpId: string) => {
    try {
      const response = await apiClient.rfp.rfpRfpFoiaCreate(rfpId);
      const foiaId = response.data.job_id;
      if (foiaId) {
        setRfps((rfps) => {
          if (!rfps) return rfps;

          return rfps.map((rfp) =>
            rfp.id === rfpId ? { ...rfp, foiaId: foiaId } : rfp
          ) as RFP[];
        });
      }
    } catch (e) {
      odoToast.caughtError(e, "Triggering FOIA");
    }
  };

  const markAsTestData = async (rfpId: string) => {
    try {
      const response = await apiClient.rfp.rfpRfpsMarkAsTestDataCreate(rfpId);
      const testDataId = response.data.test_data_id;
      if (testDataId) {
        setRfps((rfps) => {
          if (!rfps) return rfps;

          return rfps.map((rfp) =>
            rfp.id === rfpId ? { ...rfp, testDataId: testDataId } : rfp
          ) as RFP[];
        });
      }
    } catch (e) {
      odoToast.caughtError(e, "Marking as Test Data");
    }
  };

  return (
    <Rows className="p-lg grow">
      <Columns className="items-center gap-md mb-md shrink-0">
        <h1 className="text-2xl font-semibold">RFP Admin</h1>
        <Button
          icon="plus"
          variant="solid"
          text="RFP"
          onClick={() => setAddingRFP(true)}
        />
        <Button
          icon="layer-plus"
          variant="solid"
          text="CSV"
          onClick={() => setAddingCSV(true)}
        />
      </Columns>
      <PaginatedTableView
        results={rfps}
        mode="pages"
        className="grow"
        scrollableClassName="grow"
        paginatedData={paginatedData}
        searchMode="search"
        columns={[
          { name: "Created", size: "min" },
          { name: "Name" },
          { name: "Issuer", size: "min" },
          { name: "Proposals", size: "min" },
          { name: "Categories", size: "min" },
          { name: "PDF", size: "min" },
          { name: "Content", size: "min" },
          { name: "Status", size: "min" },
          { name: "Next", size: "min" },
          { name: "FOIA", size: "min" },
          { name: "Id", size: "min" },
          { name: "Test", size: "min" },
        ]}
        onSelect={(rfp) => setViewingRFP(rfp)}
        renderRow={(rfp, Cell, Row) => (
          <Row key={rfp.id}>
            <Cell>{format_date(rfp.created)}</Cell>
            <Cell>{rfp.name}</Cell>
            <Cell>{rfp.issuingOrg}</Cell>
            <Cell className="overflow-hidden">
              <Tooltip>
                <TooltipTrigger className="flex gap-xs cursor-default">
                  {rfp.orgs.map((org) => {
                    return (
                      <Pill
                        key={org}
                        text={org}
                        className="inline-block min-w-[48px]"
                      />
                    );
                  })}
                </TooltipTrigger>
                <TooltipContent>
                  <Rows className="gap-xs">
                    {rfp.orgs.map((org) => (
                      <p key={org} className="inline-block min-w-[48px]">
                        {org}
                      </p>
                    ))}
                  </Rows>
                </TooltipContent>
              </Tooltip>
            </Cell>
            <Cell className="overflow-hidden">
              <Tooltip>
                <TooltipTrigger className="flex gap-xs cursor-default">
                  {rfp.categories.map((cat) => {
                    return (
                      <Pill
                        key={cat}
                        text={cat}
                        className="inline-block min-w-[48px]"
                      />
                    );
                  })}
                </TooltipTrigger>
                <TooltipContent>
                  <Rows className="gap-xs">
                    {rfp.categories.map((cat) => (
                      <p key={cat} className="inline-block min-w-[48px]">
                        {cat}
                      </p>
                    ))}
                  </Rows>
                </TooltipContent>
              </Tooltip>
            </Cell>
            <Cell center={true}>
              {rfp.hasPDF ? (
                <Button icon="eye" onClick={() => handleViewPDF(rfp.id)} />
              ) : (
                "N/A"
              )}
            </Cell>
            <Cell center={true}>
              {rfp.hasMarkdown ? (
                <Button icon="eye" onClick={() => handleViewMarkdown(rfp.id)} />
              ) : (
                "N/A"
              )}
            </Cell>
            <Cell center={true}>
              {rfp.processingStatus === "invalid" ? (
                <Tooltip>
                  <TooltipTrigger>invalid</TooltipTrigger>
                  <TooltipContent>{rfp.invalidReason}</TooltipContent>
                </Tooltip>
              ) : (
                rfp.processingStatus
              )}
            </Cell>
            <Cell center={true}>{rfp.nextStep ?? "-"}</Cell>
            <Cell center={true}>
              {rfp.foiaId ? (
                rfp.foiaId
              ) : (
                <Button icon="inbox-in" onClick={() => triggerFoia(rfp.id)} />
              )}
            </Cell>
            <Cell center={true} className="text-center">
              <Button
                icon="copy"
                tooltip={rfp.id}
                onClick={() => copyToClipboard(rfp.id)}
              />
            </Cell>
            <Cell center={true}>
              {rfp.testDataId ? (
                rfp.testDataId
              ) : (
                <Button icon="flask" onClick={() => markAsTestData(rfp.id)} />
              )}
            </Cell>
          </Row>
        )}
      />
      <Overlay
        title="Add RFP"
        maxWidth={500}
        className="w-full"
        open={addingRFP}
        onClose={() => setAddingRFP(false)}
      >
        <AddRFPForm forAdmin={true} onRfpUploaded={async () => refresh()} />
      </Overlay>
      <Overlay
        title="CSV"
        maxWidth={500}
        className="w-full"
        open={addingCSV}
        onClose={() => setAddingCSV(false)}
      >
        <AddCSVForm />
      </Overlay>
      {viewingRFP && (
        <RFPDetailsOverlay
          onDismiss={() => setViewingRFP(null)}
          rfpId={viewingRFP.id}
          rfpName={viewingRFP.name}
        />
      )}
    </Rows>
  );
};

const AddCSVForm = () => {
  const apiClient = useApiClient();
  const fileInputRef = useRef<FileInputRef | null>(null);

  const handleFileDropped = async (file: File) => {
    const content = await file.text();
    await apiClient.rfp.rfpRfpCsvCreate({ content, source: file.name });
    fileInputRef.current?.clear();
    odoToast.success({
      title: "CSV uploaded successfully",
      text: "This will take a several minutes to process",
    });
  };

  return (
    <FileInput
      ref={fileInputRef}
      accept="text/csv"
      onFileChanged={(file) => {
        if (!file) return;
        handleFileDropped(file);
      }}
    />
  );
};

export default RFPsRoute;
