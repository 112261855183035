import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import NavigationView from "components/common/NavigationView";
import useFetchedData from "hooks/useFetchedData";
import useTriggerDownload from "hooks/useTriggerDownload";
import { format_date } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useParams } from "react-router-dom";
import { odoToast } from "lib/odoToast";
import { message_from_exception } from "utils";

const PastProposalDetailsRoute = () => {
  const { id: pastProposalId } = useParams();
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const { isStaff } = useAuthenticatedUser();
  const [pastProposal, , { error }] = useFetchedData(async () => {
    const response = await apiClient.rfp.rfpProposalPastRead(pastProposalId!);
    return response.data;
  }, [pastProposalId]);

  return (
    <AsyncLoadedDiv
      value={pastProposal}
      error={error ? message_from_exception(error) : null}
      whileLoaded={(pastProposal) => {
        return (
          <NavigationView selected="past-proposals">
            <h1 className="text-2xl font-bold mb-md">{pastProposal.name}</h1>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Created</td>
                    <td>{format_date(pastProposal.created!)}</td>
                  </tr>
                  {isStaff && (
                    <>
                      <tr>
                        <td>Is Uploaded</td>
                        <td>{pastProposal.is_uploaded ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Is Processed</td>
                        <td>{pastProposal.is_processed ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Topic</td>
                        <td>{pastProposal.topic}</td>
                      </tr>
                      <tr>
                        <td>Processing Job ID</td>
                        <td>{pastProposal.processing_job_id}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {isStaff && (
              <Columns className="gap-md mt-md">
                <Button
                  text="View PDF"
                  variant="solid"
                  onClick={() => {
                    if (!pastProposal.view_url) {
                      odoToast.error({
                        title: "No PDF Available",
                        text: "This proposal does not have a PDF version available",
                      });
                      return;
                    }
                    triggerDownload(pastProposal.view_url, "tab");
                  }}
                />
                <Button
                  text="View Markdown"
                  variant="solid-secondary"
                  onClick={() => {
                    if (!pastProposal.markdown_url) {
                      odoToast.error({
                        title: "No Markdown Available",
                        text: "This proposal does not have a markdown version available",
                      });
                      return;
                    }
                    triggerDownload(pastProposal.markdown_url, "tab");
                  }}
                />
              </Columns>
            )}
          </NavigationView>
        );
      }}
    />
  );
};

export default PastProposalDetailsRoute;
