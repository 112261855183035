import Button from "./Button";
import CurrentUser from "components/CurrentUser";
import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import OdoLogo from "./OdoLogo";
import { cn } from "lib/utils";
import Rows from "./containers/Rows";
import Columns from "./containers/Columns";
import Spacer from "./containers/Spacer";
import {
  CollapsableContainer,
  CollapsableContent,
  CollapsedContent,
} from "./containers/CollapsableContainer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import Icon from "./Icon";

type PageType =
  | "proposals"
  | "past-proposals"
  | "rfps"
  | "content-library"
  | "final-check"
  | "explore";

interface NavigationViewProps {
  children?: React.ReactNode;
  selected?: PageType;
  containerClassName?: string;
  fullWidth?: boolean;
  scrollable?: boolean;
  padContent?: boolean;

  // Elements next to page links
  extraElements?: React.ReactNode;
}

const ORDERED_PAGES: {
  type: PageType;
  text: string;
  path: string;
  staffOnly?: boolean;
}[] = [
  {
    type: "rfps",
    text: "RFPs",
    path: "/",
  },
  {
    type: "proposals",
    text: "Proposals",
    path: "/proposals/",
  },
  {
    type: "final-check",
    text: "Final Check",
    path: "/final-check/",
  },
  {
    type: "past-proposals",
    text: "Past Proposals",
    path: "/past-proposals/",
  },
  // {
  //   type: "content-library",
  //   text: "Content",
  //   path: "/content/",
  //   staffOnly: true,
  // },
  // {
  //   type: "explore",
  //   text: "Explore",
  //   path: "/rfps/explore/",
  //   staffOnly: true,
  // },
];

const NavigationView: FC<NavigationViewProps> = ({
  children,
  extraElements,
  selected,
  containerClassName,
  fullWidth = false,
  scrollable = true,
  padContent = true,
}) => {
  const navigate = useNavigate();
  const user = useOptionalAuthenticatedUser();

  return (
    <Rows className="grow mt-4xl">
      <Rows
        className={cn(
          "grow items-center",
          scrollable && "overflow-auto",
          padContent && "p-lg",
          containerClassName
        )}
      >
        <Rows
          className={cn(
            "grow w-full",
            !fullWidth && "max-w-[950px]",
            scrollable && "overflow-visible"
          )}
        >
          {children}
        </Rows>
      </Rows>
      {/* Ordered at bottom to show up above main content */}
      <Columns className="border-b px-lg justify-center fixed top-0 left-0 right-0 bg-background">
        <Columns className="grow gap-lg max-w-[950px] items-center">
          <CollapsableContainer className="grow">
            <CollapsedContent>
              <Columns className="items-center gap-lg absolute inset-0">
                <div>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Icon name="bars" size="large" className="" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="ml-sm">
                      {ORDERED_PAGES.map((page) => {
                        if (page.staffOnly && !(user?.isStaff ?? false)) {
                          return null;
                        }
                        return (
                          <DropdownMenuItem
                            key={page.type}
                            onClick={() => navigate(page.path)}
                            className={cn(
                              selected === page.type &&
                                "bg-background-secondary font-semibold",
                              page.staffOnly && "admin text-primary"
                            )}
                          >
                            {page.text}
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <OdoLogo size="small" className="grow" />
                <CurrentUser />
              </Columns>
            </CollapsedContent>
            <CollapsableContent>
              <Columns className="items-center gap-lg grow shrink-0">
                <OdoLogo size="small" path="/" className="-ml-8" />
                <Columns className="overflow-x-auto">
                  {ORDERED_PAGES.map((page) => {
                    if (page.staffOnly && !(user?.isStaff ?? false)) {
                      return null;
                    }
                    return (
                      <Button
                        key={page.type}
                        text={page.text}
                        disabled={selected === page.type}
                        variant="navigation"
                        className={
                          page.staffOnly
                            ? "admin text-primary whitespace-nowrap"
                            : ""
                        }
                        navigate={page.path}
                      />
                    );
                  })}
                  {extraElements}
                </Columns>
                <Spacer />
                <CurrentUser />
              </Columns>
            </CollapsableContent>
          </CollapsableContainer>
        </Columns>
      </Columns>
    </Rows>
  );
};

export default NavigationView;
