import { RFPSearch } from "api/Api";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import MessageView from "components/common/containers/MessageView";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import Spacer from "components/common/containers/Spacer";
import Icon from "components/common/Icon";
import FullRFPView from "components/rfps/FullRFPView";
import RFPListItemView from "components/rfps/RFPListItemView";
import { SingleList } from "hooks/useRFPsInList";
import { useConfirm, useTextAreaConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import {
  useAuthenticatedUser,
  useSetAuthenticatedUser,
} from "providers/AuthenticatedUserProvider";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ListActionsButton from "./ListActionsButton";
import ToggleFavoriteButton from "./ToggleFavoriteButton";
import { odoToast } from "lib/odoToast";
import { copyToClipboard } from "lib/clipboard";

interface SingleListViewProps {
  singleList: SingleList;
}

export const SingleListView: FC<SingleListViewProps> = ({ singleList }) => {
  const {
    rfps,
    listName,
    listId,
    deleteList,
    hasOtherLists,
    renameList,
    addNewList,
  } = singleList;
  const navigate = useNavigate();
  const [viewingRFP, setViewingRFP] = useState<RFPSearch | null>(null);
  const confirm = useConfirm();
  const textAreaConfirm = useTextAreaConfirm();
  const { favoriteListId } = useAuthenticatedUser();
  const setUser = useSetAuthenticatedUser();
  const apiClient = useApiClient();

  const copyLink = (rfp: RFPSearch) => {
    copyToClipboard(`${window.location.origin}/rfps/${rfp.id}/`);
  };

  const handleAddNewList = async () => {
    const result = await textAreaConfirm("Add to New List", {
      placeholder: "Enter the name of the new list",
      buttons: [
        {
          text: "Cancel",
          id: "cancel",
        },
        {
          text: "Save",
          id: "save",
          variant: "solid",
        },
      ],
    });

    if (result.id !== "save" || !result.text) {
      return;
    }

    const listId = addNewList(result.text);
    navigate(`/lists/${listId}/`);
  };

  const handleToggleFavoriteList = async () => {
    const originalFavoriteListId = favoriteListId;
    if (originalFavoriteListId && originalFavoriteListId !== listId) {
      if (
        !(await confirm("Are you sure you want to change your favorite list?"))
      ) {
        return;
      }
    }

    const newFavoriteListId = favoriteListId === listId ? null : listId;

    setUser((user) => {
      if (!user) {
        return null;
      }
      return { ...user, favoriteListId: newFavoriteListId };
    });

    try {
      await apiClient.auth.authUserDetailsPartialUpdate({
        favorite_list_id: newFavoriteListId,
      });
    } catch (error) {
      odoToast.caughtError(error, "Updating Favorite List");
      setUser((user) => {
        if (!user) {
          return null;
        }
        return { ...user, favoriteListId: originalFavoriteListId };
      });
    }
  };

  return (
    <Rows>
      <Columns className="gap-md">
        <Rows>
          <h1 className="text-2xl font-semibold">Shortlisted RFPs</h1>
          <p className="text-secondary">
            Make a go/no-go decision on your shortlisted RFPs
          </p>
        </Rows>
        <Spacer />
        <Button
          icon="plus"
          variant="outline"
          text="Add New List"
          onClick={handleAddNewList}
        />
      </Columns>
      <Columns className="items-baseline my-md gap-md">
        {hasOtherLists && (
          <>
            <Button
              navigate="/all-lists/"
              text="All Lists"
              className="px-none text-xl"
            />
            <Icon name="chevron-right" className="text-secondary" />
          </>
        )}
        <Columns className="items-baseline">
          {hasOtherLists && <ToggleFavoriteButton listId={listId} />}
          <h2 className="text-xl font-semibold">{listName}</h2>
          {hasOtherLists && (
            <ListActionsButton
              listName={listName}
              deleteList={deleteList}
              renameList={renameList}
            />
          )}
        </Columns>
        <p className="text-secondary">
          {rfps.length === 1 ? "1 RFP" : `${rfps.length} RFPs`}
        </p>
      </Columns>
      <style>
        {`
          .rfp-item-enter {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
          }
          .rfp-item-enter-active {
            opacity: 1;
            max-height: 500px;
            transition: all 300ms ease-in;
          }
          .rfp-item-exit {
            opacity: 1;
            max-height: 500px;
          }
          .rfp-item-exit-active {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 300ms ease-out;
          }
        `}
      </style>
      <Rows className="gap-md">
        <TransitionGroup component={null}>
          {rfps.map((rfp) => (
            <CSSTransition key={rfp.id} timeout={300} classNames="rfp-item">
              <RFPListItemView
                rfp={rfp}
                onViewDetails={() => setViewingRFP(rfp)}
                showSelected={false}
                intent="go-no-go"
                primaryListId={listId}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
        {rfps.length === 0 && (
          <MessageView className="my-lg" title="List Empty" icon="inbox">
            <p className="text-center max-w-[400px]">
              You don't have any RFPs in this list.
              <br />
              Find new RFPs on the RFP page and add them to this list to reivew
              them later.
            </p>
            <Button
              icon="magnifying-glass"
              text="Find RFPs"
              navigate="/rfps/"
              variant="solid"
            />
          </MessageView>
        )}
      </Rows>
      {viewingRFP && (
        <Overlay
          title={viewingRFP.display_name}
          extraTitleBarElements={
            <Button
              icon="link-simple"
              className="ml-sm"
              onClick={() => copyLink(viewingRFP)}
            />
          }
          variant="bordered"
          onClose={() => setViewingRFP(null)}
        >
          <FullRFPView rfp={viewingRFP} intent="go-no-go" />
        </Overlay>
      )}
    </Rows>
  );
};
