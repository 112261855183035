import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, ReactNode } from "react";
import { Navigate, PathRouteProps } from "react-router-dom";
import AdminRoute from "./AdminRoute";
import OrgsRoute from "./orgs/OrgsRoute";
import PromptsRoute from "./prompts/PromptsRoute";
import PromptDetailsRoute from "./prompts/PromptDetailsRoute";
import SearchRoute from "./search/SearchRoute";
import ProposalsRoute from "./proposals/ProposalsRoute";
import EventsRoute from "./debug/EventsRoute";
import RFPsRoute from "./rfps/RFPsRoute";
import Rows from "components/common/containers/Rows";
import NavigationView from "components/common/NavigationView";
import AdminEditorRoute from "./editor/AdminEditorRoute";
import { setRedirectAfterLogin } from "utils";
import RFPCategoriesRoute from "./rfp-categories/RFPCategoriesRoute";
import UIPlaygroundRoute from "./UIPlaygroundRoute";

interface AdminRouteProps extends PathRouteProps {
  name?: string;
  fullWidth?: boolean;
  requireStaff?: boolean;
}

const adminRoutes: AdminRouteProps[] = [
  {
    path: "/",
    element: <AdminRoute />,
    name: "Admin Home",
    fullWidth: false,
    requireStaff: false,
  },
  {
    path: "/orgs",
    element: <OrgsRoute />,
  },
  {
    path: "/rfps",
    element: <RFPsRoute />,
  },
  {
    path: "/prompts",
    element: <PromptsRoute />,
  },
  {
    path: "/prompts/:id",
    element: <PromptDetailsRoute />,
  },
  {
    path: "/search",
    element: <SearchRoute />,
    name: "Search",
  },
  {
    path: "/rfp-categories",
    element: <RFPCategoriesRoute />,
  },
  {
    path: "/proposals",
    element: <ProposalsRoute />,
    name: "Unpublished Proposals",
    fullWidth: false,
  },
  {
    path: "/events",
    element: <EventsRoute />,
    name: "Events",
  },
  {
    path: "/editor",
    element: <AdminEditorRoute />,
    name: "Editor",
  },
  {
    path: "/ui-playground",
    element: <UIPlaygroundRoute />,
    name: "UI Playground",
  },
];

const RequireAdmin: FC<{
  children: ReactNode;
  title?: string;
  fullWidth: boolean;
  requireStaff?: boolean;
}> = ({ children, title, fullWidth, requireStaff }) => {
  const user = useOptionalAuthenticatedUser();

  if (user === undefined) {
    return null;
  }

  const isStaff = user?.isStaff ?? false;
  const isWriter = isStaff || (user?.isWriter ?? false);
  let hasPermission = false;
  if (requireStaff && isStaff) {
    hasPermission = true;
  } else if (!requireStaff && isWriter) {
    hasPermission = true;
  }

  if (!hasPermission) {
    // Redirect to login page if not authorized
    setRedirectAfterLogin();
    return <Navigate to="/login" />;
  }

  return (
    <NavigationView fullWidth={fullWidth} scrollable={false}>
      {title && (
        <h1 className="text-2xl font-semibold w-full mb-md">{title}</h1>
      )}
      <Rows className="grow admin">{children}</Rows>
    </NavigationView>
  );
};

const wrapAdminRoutes = (routes: AdminRouteProps[]) => {
  return routes.map((route) => {
    return {
      ...route,
      path: `/admin${route.path}`,
      element: (
        <RequireAdmin
          title={route.name}
          fullWidth={route.fullWidth ?? true}
          requireStaff={route.requireStaff}
        >
          {route.element}
        </RequireAdmin>
      ),
    };
  });
};

export default wrapAdminRoutes(adminRoutes);
