import Button, { ButtonProps } from "components/common/Button";
import LoadableView from "components/common/containers/LoadableView";
import Overlay from "components/common/containers/overlays/Overlay";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import { IconName } from "components/common/Icon";
import { cn } from "lib/utils";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { message_from_exception } from "utils";
import { v4 } from "uuid";
import Input from "components/common/forms/Input";

interface AlertButton extends Omit<ButtonProps, "onClick"> {
  id: string;

  // If provided, this function will be called when the alert is about to return
  // If it returns a string, or it throws an error, it will be displayed as an error
  process?: (buttonId: string) => Promise<void>;
}

export interface TextAreaButton extends Omit<AlertButton, "process"> {
  process?: (buttonId: string, text: string) => Promise<void>;
}

interface CustomOptions {
  body?: ReactNode;
  buttons: AlertButton[];
  buttonOrientation?: "horizontal" | "vertical";
  // Id to be returned when the user dismisses the alert (e.g. by clicking the backdrop,
  // pressing escape, or clicking the dismiss button)
  // If not provided, the alert will not be dismissable without clicking a button
  dismissId?: string;
  defaultId?: string;
  fillWidth?: boolean;
}

interface AlertOptions {
  body?: ReactNode;
  contactSupport?: boolean;
  dismissId?: string;
}

interface ConfirmOptions {
  body?: ReactNode;
  yesText?: string;
  yesDestructive?: boolean;
  noText?: string;
  defaultYes?: boolean;
}

interface ConfirmTextAreaOptions
  extends Omit<CustomOptions, "body" | "buttons"> {
  initialText?: string;
  placeholder?: string;
  height?: number;
  buttons: TextAreaButton[];
  multiline?: boolean; // Defaults to true
}

export interface Choice {
  text: string;
  id: string;
  icon?: IconName;
  variant?: ButtonProps["variant"];
  tooltip?: string;
}

interface ChooseOptions {
  body?: ReactNode;
  choices: Choice[];
  dismissId?: string;
}

interface AlertProviderData {
  show: (message: string, options: CustomOptions) => Promise<string>;
}

const AlertProviderContext = createContext<AlertProviderData | null>(null);

interface AlertProviderProps {
  children?: ReactNode;
}

interface Alert {
  message: string;
  options: CustomOptions;
  id: string;
  promise: (buttonId: string) => void;
}

/**
 * Allow using a useConfirm hook that returns a function to provide
 * custom confirmation dialogs with a similar affordance to the
 * browser's built-in confirm dialog.
 */
export const AlertProvider: FC<AlertProviderProps> = ({ children }) => {
  const [pendingAlerts, setPendingAlerts] = useState<Alert[]>([]);

  const show = useCallback(
    (message: string, options: CustomOptions) => {
      const id = v4();
      return new Promise<string>((resolve) => {
        setPendingAlerts((prev) => [
          ...prev,
          { message, id, options, promise: resolve },
        ]);
      });
    },
    [setPendingAlerts]
  );

  return (
    <AlertProviderContext.Provider value={{ show }}>
      {children}
      {pendingAlerts.map(({ promise, ...props }) => (
        <AlertContent
          key={props.id}
          {...props}
          promise={promise}
          hide={() =>
            setPendingAlerts((prev) =>
              prev.filter((a) => a.promise !== promise)
            )
          }
        />
      ))}
    </AlertProviderContext.Provider>
  );
};

const AlertContent: FC<Alert & { hide: () => void }> = ({
  message,
  options,
  promise,
  hide,
}) => {
  const selectId = useCallback(
    (id: string) => {
      hide();
      promise(id);
    },
    [hide, promise]
  );

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!options.defaultId) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && options.defaultId) {
        e.preventDefault();
        selectId(options.defaultId);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [options.defaultId, selectId]);

  return (
    <Overlay
      maxWidth={500}
      title={message}
      onClose={
        !!options.dismissId ? () => selectId(options.dismissId!) : undefined
      }
      className={options.fillWidth ? "w-full" : undefined}
    >
      <LoadableView
        isLoading={loading}
        className="flex flex-col mt-8 text-sm gap-lg font-medium"
      >
        {options.body && <div>{options.body}</div>}
        <div
          className={cn(
            "flex gap-md justify-end",
            options.buttonOrientation === "vertical" ? "flex-col" : ""
          )}
        >
          {options.buttons.map((button) => {
            const { id, process, ...buttonProps } = button;
            return (
              <Button
                key={id}
                {...buttonProps}
                onClick={async () => {
                  if (button.process) {
                    try {
                      setLoading(true);
                      await button.process(id);
                    } catch (e) {
                      setError(message_from_exception(e));
                      return;
                    } finally {
                      setLoading(false);
                    }
                  }
                  selectId(button.id);
                }}
              />
            );
          })}
        </div>
        {error && (
          <div className="text-destructive text-center">Error: {error}</div>
        )}
      </LoadableView>
    </Overlay>
  );
};

export const useConfirm = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmProvider");
  }
  return async (message: string, options: ConfirmOptions = {}) => {
    const yesButton: AlertButton = {
      text: options.yesText ?? "Yes",
      variant: options.yesDestructive === true ? "destructive" : "DEFAULT",
      id: "yes",
    };
    const noButton: AlertButton = {
      text: options.noText ?? "No",
      variant: "DEFAULT",
      id: "no",
    };
    let buttons: AlertButton[];
    if (options.defaultYes) {
      yesButton.variant = "solid";
      buttons = [yesButton, noButton];
    } else {
      noButton.variant = "solid-secondary";
      buttons = [noButton, yesButton];
    }
    const result = await context.show(message, {
      body: options.body,
      buttons,
    });
    return result === "yes";
  };
};

export const useAlert = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useAlert must be used within a ConfirmProvider");
  }
  return useCallback(
    async (message: string, options: AlertOptions = {}) => {
      let buttons: AlertButton[] = [
        {
          text: "OK",
          variant: "DEFAULT",
          id: "ok",
        },
      ];
      if (options.contactSupport === true) {
        buttons.push({
          text: "Contact Support",
          variant: "solid",
          id: "contact",
        });
      }
      const result = await context.show(message, {
        body: options.body,
        buttons,
        dismissId: options.dismissId,
      });
      if (result === "contact") {
        window.open("mailto:support@odo.do");
      }
    },
    [context]
  );
};

export const useTextAreaConfirm = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useTextAreaAlert must be used within a ConfirmProvider");
  }
  const text = useRef("");
  return async (message: string, options: ConfirmTextAreaOptions) => {
    const multiline = options.multiline ?? true;

    const result = await context.show(message, {
      ...options,
      fillWidth: true,
      buttons: options.buttons.map((button) => ({
        ...button,
        process: async () => {
          await button.process?.(button.id, text.current);
        },
      })),
      body: multiline ? (
        <NaturalHeightTextArea
          className="w-full -mb-md"
          value={options.initialText}
          style={{
            minHeight: options.height || undefined,
            resize: options.height ? "none" : undefined,
          }}
          placeholder={options.placeholder}
          onChange={(value) => (text.current = value)}
        />
      ) : (
        <Input
          className="w-full -mb-md"
          placeholder={options.placeholder}
          onChange={(e) => (text.current = e.target.value)}
        />
      ),
    });
    return { id: result, text: text.current };
  };
};

export const useChoose = () => {
  const context = useContext(AlertProviderContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmProvider");
  }
  const show = context.show;
  return useCallback(
    async (message: string, options: ChooseOptions) => {
      const buttons: AlertButton[] = options.choices.map((choice) => ({
        text: choice.text,
        variant: choice.variant ?? "solid-secondary",
        icon: choice.icon,
        id: choice.id,
        tooltip: choice.tooltip,
      }));
      return await show(message, {
        body: options.body,
        buttons,
        buttonOrientation:
          options.choices.length > 2 ? "vertical" : "horizontal",
        dismissId: options.dismissId,
      });
    },
    [show]
  );
};
