import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import { useEditorRef } from "@udecode/plate-common";
import Pill from "components/common/Pill";
import {
  addBlockToSection,
  getPreviousSections,
  getSection,
} from "lib/plate/getPreviousSection";
import { Choice, useChoose } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import {
  ActiveSection,
  DropLocation,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { FC, useCallback, useMemo, useState } from "react";
import { odoToast } from "lib/odoToast";
import { SectionInfo } from "odo";
import { Category, singularNameFromCategory } from "hooks/useContentLibrary";
import { IconName } from "components/common/Icon";
import useContentLibraryActions from "hooks/useContentLibraryActions";

interface ProposalDragDropProps {
  children?: React.ReactNode;
}

type DraggingType = "block" | Category;

const ProposalDragDrop: FC<ProposalDragDropProps> = ({ children }) => {
  const apiClient = useApiClient();
  const editor = useEditorRef();
  const { details } = useProposalData();
  const { activeSection, setDroppingSection } = useEditorDocData();
  const { isWriter } = useAuthenticatedUser();
  const choose = useChoose();
  const [draggingType, setDraggingType] = useState<DraggingType | null>(null);
  const { insertItem } = useContentLibraryActions(details.id);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { type } = parseRawId(event.active.id.toString());
    setDraggingType(type as any);
  }, []);

  const handleBlockDragEnd = useCallback(
    async (
      blockId: string,
      data: any,
      sectionNode: SectionInfo,
      activeSection: ActiveSection
    ) => {
      const previousSections = getPreviousSections(activeSection.id, editor, 3);
      let previousNames = previousSections?.map((s) => s.name.trim()) ?? [];
      previousNames = previousNames.filter((n) => n.length > 0);
      if (activeSection.mode === "into") {
        let choices: Choice[] = [
          {
            id: "update",
            text: "Update Section",
            tooltip:
              "Link the block to the section and update the content to incorporate it (if there is existing content).",
          },
          {
            id: "add",
            text: "Add Subsection",
            tooltip: "Create a new subsection to cover this block",
          },
        ];

        if (isWriter) {
          choices.push({
            id: "cover",
            text: "Cover Only",
            tooltip: "Link the block to the section without making any changes",
          });
        }

        const result = await choose("How would you like to incorporate this?", {
          dismissId: "cancel",
          choices,
        });
        if (result === "add") {
          const level = sectionNode.level + 1;
          const previousName = activeSection.name.trim();
          if (previousName.length > 0) {
            previousNames.push(previousName);
          }
          await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
            target_id: activeSection.id,
            target_name: activeSection.name,
            mode: "below",
            level,
            rfp_block_id: blockId,
            previous_headings: previousNames,
          });
        } else if (result === "update" || result === "cover") {
          addBlockToSection(data.current.block, activeSection.id, editor);
          await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
            target_id: activeSection.id,
            target_name: activeSection.name,
            mode: "rewrite",
            level: -1,
            rfp_block_id: blockId,
            update_content: result === "update",
          });
        } else if (result === "cancel") {
          return;
        } else {
          throw new Error("Invalid choice");
        }
      } else if (activeSection.mode === "above") {
        let level = 1;
        if (previousSections && previousSections.length > 0) {
          level = previousSections[previousSections.length - 1].level;
        }
        await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
          target_id: activeSection.id,
          target_name: activeSection.name,
          mode: "above",
          level,
          rfp_block_id: blockId,
          previous_headings: previousNames,
        });
      } else if (activeSection.mode === "below") {
        const previousName = activeSection.name.trim();
        if (previousName.length > 0) {
          previousNames.push(previousName);
        }
        const level = sectionNode.level;
        await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
          target_id: activeSection.id,
          target_name: activeSection.name,
          mode: "below",
          level,
          rfp_block_id: blockId,
          previous_headings: previousNames,
        });
      }
    },
    [apiClient.rfp, choose, details.id, editor, isWriter]
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (activeSection) {
        try {
          const sectionNode = getSection(activeSection.id, editor);
          if (!sectionNode) {
            throw new Error("Section not found");
          }
          const { type, id } = parseRawId(event.active.id.toString());
          switch (type) {
            case "block":
              await handleBlockDragEnd(
                id,
                event.active.data,
                sectionNode,
                activeSection
              );
              break;
            case "project":
            case "person":
            case "overview":
            case "cover_letter":
            case "subcontractor":
              insertItem(id, type, sectionNode, activeSection);
              break;
            default:
              odoToast.error({
                title: "Invalid File Type",
                text: "Cannot handle dropping this type of file",
              });
          }
        } catch (e) {
          odoToast.caughtError(e, "Dropping Content");
        }
      }
      setDroppingSection(null);
      setDraggingType(null);
    },
    [activeSection, editor, handleBlockDragEnd, insertItem, setDroppingSection]
  );
  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const fullId = (event.over?.id as string) ?? null;
      if (fullId) {
        const id = fullId.split("_")[0];
        const location = fullId.split("_")[1];
        const section = getSection(id, editor);
        setDroppingSection({
          id,
          location: location as DropLocation,
          name: section?.name ?? "",
          samples: section?.samples ?? null,
        });
      } else {
        setDroppingSection(null);
        setDraggingType(null);
      }
    },
    [editor, setDroppingSection]
  );

  const handleDragCancel = useCallback(() => {
    setDroppingSection(null);
    setDraggingType(null);
  }, [setDroppingSection]);

  const activationConstraint = useMemo(() => {
    return {
      activationConstraint: {
        delay: 300,
        tolerance: 900,
        // tolerance: 5,
      },
    };
  }, []);

  const sensors = useSensors(useSensor(PointerSensor, activationConstraint));

  const dragOverlay = useMemo(() => {
    return (
      <DragOverlay
        className="flex items-center justify-center"
        modifiers={[snapCenterToCursor]}
        dropAnimation={null}
      >
        {draggingType && (
          <Pill
            text={draggingText(draggingType)}
            icon={draggingIcon(draggingType)}
            className="bg-primary cursor-grabbing text-xs text-[white]"
          />
        )}
      </DragOverlay>
    );
  }, [draggingType]);

  return (
    <DndContext
      collisionDetection={pointerWithin}
      sensors={sensors}
      autoScroll={false}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragCancel={handleDragCancel}
    >
      {children}
      {dragOverlay}
    </DndContext>
  );
};

const parseRawId = (rawId: string) => {
  const components = rawId.split("_");
  const type = components.slice(0, -1).join("_");
  const id = components[components.length - 1];
  return { type, id };
};

const draggingText = (type: DraggingType) => {
  switch (type) {
    case "block":
      return "Block";
    default:
      return singularNameFromCategory(type);
  }
};

const draggingIcon = (type: DraggingType): IconName => {
  switch (type) {
    case "block":
      return "square-check";
    case "cover_letter":
      return "envelope";
    case "all":
    case "overview":
      return "square-list";
    case "person":
      return "person";
    case "project":
      return "briefcase";
    case "subcontractor":
      return "people-group";
    case "approach":
      return "square-list";
  }
};

export default ProposalDragDrop;
