import { FC, useEffect, useRef, useState } from "react";
import { SearchConfiguratorProps } from "./SearchConfiguratorProps";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import { debounce } from "lodash";
import { useApiClient } from "providers/ApiClientProvider";
import { NAICSSector, SavedSearchDetail } from "api/Api";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import Icon from "components/common/Icon";
import LoadableView from "components/common/containers/LoadableView";

const NAICSConfigurator: FC<SearchConfiguratorProps> = ({
  search,
  updateSearch,
}) => {
  const [query, setQuery] = useState("");
  const apiClient = useApiClient();
  const [results, setResults] = useState<NAICSSector[] | null | undefined>(
    undefined
  );

  const debouncedSearch = useRef(
    debounce(async (query: string) => {
      if (query.length === 0) {
        setResults(undefined);
        return;
      }
      setResults(null);
      const response = await apiClient.rfp.rfpNaicsSearchList({
        // @ts-expect-error
        query: {
          q: query,
        },
      });
      setResults(response.data);
    }, 500)
  );

  useEffect(() => {
    debouncedSearch.current(query);
  }, [query]);

  return (
    <Rows>
      <Input value={query} onChange={(e) => setQuery(e.target.value)} />
      <Rows className="border rounded-sm min-h-[80px] max-h-[300px] overflow-y-auto">
        {results === undefined ? (
          <CurrentSelection search={search} updateSearch={updateSearch} />
        ) : (
          <SearchResults
            results={results}
            search={search}
            updateSearch={updateSearch}
          />
        )}
      </Rows>
    </Rows>
  );
};

const SearchResults: FC<{
  results: NAICSSector[] | null;
  search: SavedSearchDetail;
  updateSearch: (
    update: Partial<SavedSearchDetail>,
    saveToRemote?: boolean
  ) => void;
}> = ({ results, search, updateSearch }) => {
  if (results?.length === 0) {
    return (
      <p className="text-center text-sm text-foreground-muted">
        No results found
      </p>
    );
  }

  return (
    <LoadableView isLoading={results === null} className="grow overflow-y-auto">
      {results?.map((r) => (
        <div key={r.id}>
          <NAICSResult result={r} search={search} updateSearch={updateSearch} />
          {r.children && (
            <Rows className="pl-lg">
              {r.children.map((s) => (
                <>
                  <NAICSResult
                    result={s}
                    search={search}
                    updateSearch={updateSearch}
                  />
                  {s.children && (
                    <Rows className="pl-lg">
                      {s.children.map((l) => (
                        <NAICSResult
                          result={l}
                          search={search}
                          updateSearch={updateSearch}
                        />
                      ))}
                    </Rows>
                  )}
                </>
              ))}
            </Rows>
          )}
        </div>
      ))}
    </LoadableView>
  );
};

const CurrentSelection: FC<{
  search: SavedSearchDetail;
  updateSearch: (
    update: Partial<SavedSearchDetail>,
    saveToRemote?: boolean
  ) => void;
}> = ({ search, updateSearch }) => {
  if (search.naics_codes.length === 0) {
    return (
      <p className="text-center text-sm text-foreground-muted">
        Add NAICS codes to your search
      </p>
    );
  }
  return (
    <>
      {search.naics_codes.map((code) => (
        <NAICSResult
          key={code}
          result={{ id: code, title: code }}
          search={search}
          updateSearch={updateSearch}
        />
      ))}
    </>
  );
};

const NAICSResult: FC<{
  result: { id: string | number; title: string };
  search: SavedSearchDetail;
  updateSearch: (
    update: Partial<SavedSearchDetail>,
    saveToRemote?: boolean
  ) => void;
}> = ({ result, search, updateSearch }) => {
  const isChecked = search.naics_codes.includes(result.id.toString());

  const handleToggle = () => {
    if (isChecked) {
      updateSearch({
        naics_codes: search.naics_codes.filter(
          (code) => code !== result.id.toString()
        ),
      });
    } else {
      updateSearch({
        naics_codes: [...search.naics_codes, result.id.toString()],
      });
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          className="whitespace-nowrap px-sm py-xs hover:bg-background-selected rounded-sm truncate max-w-[400px] cursor-pointer shrink-0"
          onClick={handleToggle}
        >
          <Icon
            name={isChecked ? "square-check" : "square"}
            className="inline-block p-sm"
          />
          {result.title}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p>{result.title}</p>
      </TooltipContent>
    </Tooltip>
  );
};

export default NAICSConfigurator;
