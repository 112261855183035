import NavigationView from "components/common/NavigationView";
import { MultipleListsSplashView } from "components/lists/MultipleListsSplashView";
import { SingleListView } from "components/lists/SingleListView";
import useRFPsInList from "hooks/useRFPsInList";
import { ShortListsProvider } from "providers/ShortListsProvider";
import { FC } from "react";
import { useParams } from "react-router-dom";

interface ListsRouteProps {
  forceAll?: boolean;
}

const ListsRoute: FC<ListsRouteProps> = (props) => {
  return (
    <NavigationView>
      <ShortListsProvider>
        <ListsContent {...props} />
      </ShortListsProvider>
    </NavigationView>
  );
};

const ListsContent: FC<ListsRouteProps> = ({ forceAll }) => {
  const { listId } = useParams();
  const rfpsInList = useRFPsInList(listId, forceAll);

  switch (rfpsInList.id) {
    case "loading":
      return <div>Loading...</div>;
    case "single":
      return <SingleListView singleList={rfpsInList} />;
    case "multiple":
      return <MultipleListsSplashView multipleLists={rfpsInList} />;
  }
};

export default ListsRoute;
