import { BaseContentItem } from "hooks/section-details/useSectionDetails";
import { SampleContent } from "odo";
import Rows from "../containers/Rows";
import Scrollable from "../containers/Scrollable";
import { useState } from "react";
import Overlay from "../containers/overlays/Overlay";
import ContentLibraryView from "components/content-library/ContentLibraryView";
import { ItemSearch } from "api/Api";
import Button from "../Button";
import Columns from "../containers/Columns";
import { cn } from "lib/utils";
import { useConfirm } from "providers/AlertProvider";

interface ContentLibraryBasesPickerProps {
  baseContentItems: BaseContentItem[];
  setBaseContentItems: (baseContentItems: BaseContentItem[]) => void;
  samples: SampleContent[];
  proposalId: string;
  sectionTitle: string;
  sectionDetails: string;
}

interface DisplayItemData {
  sample: SampleContent | null;
  baseContentItem: BaseContentItem | null;
}

const ContentLibraryBasePicker: React.FC<ContentLibraryBasesPickerProps> = ({
  baseContentItems,
  setBaseContentItems,
  samples,
  proposalId,
  sectionTitle,
  sectionDetails,
}) => {
  const displayItems = getDisplayItems(baseContentItems, samples);
  const [selectedDisplayItem, setSelectedDisplayItem] =
    useState<DisplayItemData | null>(null);

  const handleChooseItem = async (item: ItemSearch | null) => {
    if (!selectedDisplayItem) return;

    const newBaseContentItems = updatingBaseContentItemWithChosenItem(
      baseContentItems,
      selectedDisplayItem,
      item,
      samples
    );
    setBaseContentItems(newBaseContentItems);
    setSelectedDisplayItem(null);
  };

  const flaggedItemIds = baseContentItems
    .map((item) => item.itemId)
    .filter(
      (itemId) => itemId !== selectedDisplayItem?.baseContentItem?.itemId
    );

  let defaultSearchQuery = "";
  let contentEmbedding: number[] | undefined;
  if (selectedDisplayItem) {
    if (selectedDisplayItem.sample) {
      defaultSearchQuery = selectedDisplayItem.sample.title;
      contentEmbedding = selectedDisplayItem.sample.embedding;
    } else if (sectionDetails) {
      defaultSearchQuery = `${sectionTitle} ^ ${sectionDetails}`;
      contentEmbedding = undefined;
    } else if (sectionTitle) {
      defaultSearchQuery = sectionTitle;
      contentEmbedding = undefined;
    }
  }

  return (
    <>
      <Scrollable className="border rounded-sm">
        <Rows>
          {displayItems.map((displayItem, i) => (
            <DisplayItemListElement
              key={i}
              displayItem={displayItem}
              onPickItem={() => {
                setSelectedDisplayItem(displayItem);
              }}
              onRemoveItem={async () => {
                setBaseContentItems(
                  baseContentItems.filter(
                    (item) =>
                      item.itemId !== displayItem.baseContentItem?.itemId
                  )
                );
              }}
            />
          ))}
          <Button
            icon="plus"
            text="Add Base"
            onClick={() => {
              setSelectedDisplayItem({ sample: null, baseContentItem: null });
            }}
          />
        </Rows>
      </Scrollable>

      {selectedDisplayItem && (
        <Overlay
          onClose={() => {
            setSelectedDisplayItem(null);
          }}
          title={`Select Base for ${
            selectedDisplayItem.sample?.title || sectionTitle
          }`}
          variant="bordered"
          className="grow"
        >
          <ContentLibraryView
            proposalId={proposalId}
            size="full"
            initialItemId={selectedDisplayItem.baseContentItem?.itemId}
            initialCategory={
              !!selectedDisplayItem.baseContentItem ? "all" : "approach"
            }
            chooseItem={handleChooseItem}
            defaultSearchQuery={defaultSearchQuery}
            contentEmbedding={contentEmbedding}
            flaggedItemIds={flaggedItemIds}
          />
        </Overlay>
      )}
    </>
  );
};

/**
 * Given a list of base content items and samples, return a list of items to display
 *
 * All samples must always be displayed (as blanks if there isn't a corresponding base content item)
 * Base items that are associated with a sample should be displayed in the order they are in the samples.
 * Any remaining base items should be displayed at the end in the order they are in the list.
 */
const getDisplayItems = (
  baseContentItems: BaseContentItem[],
  samples: SampleContent[]
) => {
  const displayItems: DisplayItemData[] = [];
  let remainingBaseContentItems = [...baseContentItems];
  for (const [i, sample] of samples.entries()) {
    const baseContentItem = remainingBaseContentItems.find(
      (item) => item.sampleIndex === i
    );
    displayItems.push({ sample, baseContentItem: baseContentItem ?? null });
    if (baseContentItem) {
      remainingBaseContentItems = remainingBaseContentItems.filter(
        (item) => item !== baseContentItem
      );
    }
  }
  for (const baseContentItem of remainingBaseContentItems) {
    displayItems.push({ sample: null, baseContentItem });
  }
  return displayItems;
};

/**
 * Given a list of base content items and a chosen item, return a new list of base content items
 * with the chosen itemId added to the base content item
 *
 * This requires:
 * 1. If a base item
 */
const updatingBaseContentItemWithChosenItem = (
  baseContentItems: BaseContentItem[],
  displayItem: DisplayItemData,
  chosenItem: ItemSearch | null,
  samples: SampleContent[]
): BaseContentItem[] => {
  if (displayItem.baseContentItem) {
    // If the display item has a base content item, we need to update the
    // existing base content item with the chosen item
    if (!chosenItem) {
      return baseContentItems.filter(
        (item) => item.itemId !== displayItem.baseContentItem!.itemId
      );
    }
    return baseContentItems.map((item) =>
      item.itemId === displayItem.baseContentItem!.itemId
        ? { ...item, itemId: chosenItem.id! }
        : item
    );
  } else if (!displayItem.sample) {
    if (!chosenItem) {
      return baseContentItems;
    }
    // We're adding a completely new base item
    return [...baseContentItems, { itemId: chosenItem.id! }];
  } else {
    if (!chosenItem) {
      return baseContentItems;
    }
    // If the display item has a sample but no base content item, we need to add a new
    // base content item with the chosen item.
    //
    // It must be added in the appropriate order (based on position of the sample within
    // the samples array) so that the final generation uses the base content in the correct
    // order.

    // Find the index of the sample in the samples array
    const sampleIndex = samples.findIndex(
      (sample) => sample.title === displayItem.sample!.title
    );
    if (sampleIndex === -1) {
      throw new Error("Sample not found in samples array");
    }

    // Loop through base content items, tracking the current sample index
    // and insert the chosen item once `sampleIndex` is reached
    let currentSampleIndex = 0;
    let newBaseContentItems: BaseContentItem[] = [];
    let addedChosenItem = false;
    for (const baseContentItem of baseContentItems) {
      if (currentSampleIndex === sampleIndex) {
        newBaseContentItems.push({ itemId: chosenItem.id!, sampleIndex });
        currentSampleIndex++;
        addedChosenItem = true;
      }
      newBaseContentItems.push(baseContentItem);
      if (baseContentItem.sampleIndex) {
        currentSampleIndex++;
      }
    }
    if (!addedChosenItem) {
      newBaseContentItems.push({ itemId: chosenItem.id!, sampleIndex });
    }
    return newBaseContentItems;
  }
};

interface DisplayItemListElementProps {
  displayItem: DisplayItemData;
  onPickItem: () => void;
  onRemoveItem: () => Promise<void>;
}

const DisplayItemListElement: React.FC<DisplayItemListElementProps> = ({
  displayItem,
  onPickItem,
  onRemoveItem,
}) => {
  const confirm = useConfirm();

  return (
    <Columns
      className="border-b py-xs px-sm items-center"
      onClick={(e) => {
        e.stopPropagation();
        onPickItem();
      }}
    >
      <Button
        icon={!displayItem.baseContentItem ? "square" : "square-check"}
        onClick={async (e) => {
          e.stopPropagation();
          if (!displayItem.baseContentItem) {
            onPickItem();
          } else {
            if (
              await confirm("Are you sure you want to remove this base item?")
            ) {
              await onRemoveItem();
            }
          }
        }}
      />
      <div
        className={cn(
          "grow py-xs",
          !!displayItem.baseContentItem ? "text-secondary" : ""
        )}
      >
        {displayItem.sample?.title ?? "Manual Base"}
      </div>
    </Columns>
  );
};

export default ContentLibraryBasePicker;
