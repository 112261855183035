import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useApiClient, useDoExtraWorkBeforeLoading } from "./ApiClientProvider";
import * as Sentry from "@sentry/react";
import { posthog } from "posthog-js";
import {
  AuthenticatedUser,
  authenticatedUserFromDetails,
} from "types/AuthenticatedUser";

interface AuthenticatedUserData {
  user: AuthenticatedUser | null | undefined;
  setUser: Dispatch<SetStateAction<AuthenticatedUser | null | undefined>>;
  refresh: () => Promise<AuthenticatedUser | null>;
}

const AuthenticatedUserContext = createContext<
  AuthenticatedUserData | undefined
>(undefined);

export const AuthenticatedUserProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const apiClient = useApiClient();
  const [user, setUser] = useState<AuthenticatedUser | null | undefined>(
    undefined
  );
  const finishLoading = useDoExtraWorkBeforeLoading();

  const refresh = useCallback(async () => {
    try {
      setUser(undefined);
      const result = await apiClient.auth.authUserDetailsRead();
      const newUser = {
        ...authenticatedUserFromDetails(result.data),
        isPresent: true,
      };
      setUser(newUser);
      return newUser;
    } catch {
      setUser(null);
      return null;
    }
  }, [apiClient.auth, setUser]);

  useEffect(() => {
    const fetch = async () => {
      await refresh();
      finishLoading();
    };
    fetch();
  }, [refresh, finishLoading]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.publicId,
        email: user.email,
      });
      posthog.identify(user.publicId);
    } else {
      Sentry.setUser(null);
      posthog.identify(undefined);
    }
  }, [user]);

  return (
    <AuthenticatedUserContext.Provider value={{ user, setUser, refresh }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

export const useOptionalAuthenticatedUser = () => {
  const data = useContext(AuthenticatedUserContext);
  if (data === undefined) {
    throw new Error(
      "useCurrentUser must be used within a AuthenticatedUserProvider"
    );
  }
  return data.user;
};

export const useAuthenticatedUser = () => {
  const user = useOptionalAuthenticatedUser();

  if (!user) {
    throw new Error("User is not authenticated");
  }
  return user;
};

export const useSetAuthenticatedUser = () => {
  const data = useContext(AuthenticatedUserContext);
  if (data === undefined) {
    throw new Error(
      "useSetCurrentUser must be used within a AuthenticatedUserProvider"
    );
  }
  return data.setUser;
};

export const useRefreshAuthenticatedUser = () => {
  const data = useContext(AuthenticatedUserContext);
  if (data === undefined) {
    throw new Error(
      "useRefreshCurrentUser must be used within a AuthenticatedUserProvider"
    );
  }
  return data.refresh;
};
