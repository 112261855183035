import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import { useConfirm } from "providers/AlertProvider";
import { useState } from "react";
import { WorkingProperty } from "types/Prompt";
import { v4 } from "uuid";
import { odoToast } from "lib/odoToast";

interface PromptPropertyViewProps {
  property: WorkingProperty | null;
  onRemove: () => void;
  onSave: (property: WorkingProperty) => void;
  onCancel: () => void;
}

const PromptPropertyView: React.FC<PromptPropertyViewProps> = ({
  property: existingProperty,
  onSave,
  onCancel,
  onRemove,
}) => {
  const confirm = useConfirm();
  const [workingProperty, setWorkingProperty] = useState<WorkingProperty>(
    existingProperty ?? {
      id: v4(),
      name: "",
      description: "",
      type: "string",
    }
  );

  const setName = (value: string) => {
    // Restrict the name to alphanumeric characters, dashes, and underscores
    const validName = value.replace(/[^a-zA-Z0-9-_]+/g, "");
    if (validName !== value) {
      odoToast.error({
        title: "Invalid Name",
        text: "Tool names can only contain alphanumeric characters, dashes, and underscores.",
      });
    }
    setWorkingProperty({ ...workingProperty, name: validName });
  };

  const handleRemove = async () => {
    const result = await confirm(
      "Are you sure you want to remove this property?",
      {
        yesDestructive: true,
        yesText: "Remove",
      }
    );
    if (result) {
      onRemove();
      onCancel();
    }
  };

  const handleSave = () => {
    if (!workingProperty.name) {
      odoToast.error({
        title: "Missing Name",
        text: "Tool name cannot be empty.",
      });
      return;
    }
    onSave(workingProperty);
  };

  return (
    <Rows className="gap-md">
      <LabeledFormInput label="Name">
        <Input
          placeholder="Unnamed Property"
          value={workingProperty.name}
          onChange={(e) => setName(e.target.value)}
        />
      </LabeledFormInput>
      <LabeledFormInput label="Description">
        <NaturalHeightTextArea
          value={workingProperty.description}
          className="min-h-[70px]"
          onChange={(text) =>
            setWorkingProperty({ ...workingProperty, description: text })
          }
          placeholder="A detailed plaintext description of what the tool does, when it should be used, and how it behaves."
        />
      </LabeledFormInput>
      <Columns className="gap-md mt-sm">
        <Button
          icon="xmark"
          text="Remove Property"
          variant="destructive"
          onClick={handleRemove}
        />
        <div className="grow" />
        <Button text="Cancel" onClick={onCancel} />
        <Button
          text="Save"
          icon="floppy-disk"
          variant="solid"
          onClick={handleSave}
        />
      </Columns>
    </Rows>
  );
};

export default PromptPropertyView;
