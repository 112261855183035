import { PopoverArrow } from "@radix-ui/react-popover";
import Columns from "components/common/containers/Columns";
import Icon, { IconName } from "components/common/Icon";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";
import useFetchedData from "hooks/useFetchedData";
import { odoToast } from "lib/odoToast";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useCallback, useMemo, useState } from "react";
import {
  isSavedSearchDraft,
  SearchConfiguratorProps,
  SearchResult,
  useAggregatedSearchResults,
} from "./SearchConfiguratorProps";
import { SavedSearchDetail } from "api/Api";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import { message_from_exception } from "utils";
import LocationConfigurator from "./LocationConfigurator";
import CustomerConfigurator from "./CustomerConfigurator";
import SwitchSearchConfig from "./SwitchSearchConfig";
import SavedSearchConfigurator from "./SaveSearchConfigurator";
import ComboBox from "components/common/menus/ComboBox";
import KeywordConfigurator, {
  useCategorySearchResults,
} from "./KeywordConfigurator";
import CategoryConfigurator from "./CategoryConfigurator";
import NAICSConfigurator from "./NAICSConfigurator";

interface RFPSearchMenuProps {
  className?: string;
  onUpdated?: () => void;
}

const useSearchResults = (
  searchText: string,
  search: SavedSearchDetail | null,
  updateSearch: (update: Partial<SavedSearchDetail>) => void
) => {
  // const { options: locationOptions, onSelected: onLocationSelected } =
  //   useLocationSearchResults(searchText, search);
  // const { options: customerOptions, onSelected: onCustomerSelected } =
  //   useCustomerSearchResults(searchText, search);
  const categoryOutput = useCategorySearchResults(searchText, search);

  const { options, onSelected } = useAggregatedSearchResults([
    { output: categoryOutput, id: "category" },
  ]);

  const hasSearch = search !== null;
  if (!hasSearch) return { options: [], onSelected: () => {} };

  return {
    options,
    onSelected,
  };
};

const RFPSearchMenu = ({ className, onUpdated }: RFPSearchMenuProps) => {
  const apiClient = useApiClient();

  const [searchText, setSearchText] = useState("");
  const [search, setSearch, { error, refresh: refreshFilter }] =
    useFetchedData(async () => {
      const result = await apiClient.rfp.rfpSavedSearchCurrentRead();
      return result.data;
    }, []);

  const refresh = useCallback(() => {
    refreshFilter();
    onUpdated?.();
  }, [refreshFilter, onUpdated]);

  const updateSearch = useCallback(
    (update: Partial<SavedSearchDetail>, saveToRemote: boolean = true) => {
      setSearch((prev) => {
        if (!prev) return null;

        const updated = {
          ...prev,
          ...update,
        };
        if (JSON.stringify(updated) === JSON.stringify(prev)) return prev;

        if (saveToRemote) {
          const applyToServer = async () => {
            try {
              const result =
                await apiClient.rfp.rfpSavedSearchCurrentPartialUpdate({
                  ...update,
                } as any);
              setSearch(result.data);
              onUpdated?.();
            } catch (error) {
              odoToast.caughtError(error, "Updating Search");
              setSearch(prev);
            }
          };
          applyToServer();
        } else {
          refresh();
        }

        return {
          ...prev,
          ...update,
        };
      });
    },
    [setSearch, apiClient]
  );

  const { options, onSelected } = useSearchResults(
    searchText,
    search,
    updateSearch
  );

  const totalKeywords = useMemo(() => {
    return (
      (search?.keywords.length ?? 0) + (search?.exclude_keywords.length ?? 0)
    );
  }, [search]);

  return (
    <div className={cn("relative", className)}>
      <Columns className="hover:drop-shadow-low transition-all rounded-sm bg-background-selected overflow-visible border">
        <SearchMenuItem
          label="Switch Search"
          icon="filter"
          search={search}
          refresh={refresh}
          badge={null}
          updateSearch={updateSearch}
          error={error}
          Configurator={SwitchSearchConfig}
        />
        <SearchMenuItem
          label="Category"
          icon="list"
          search={search}
          refresh={refresh}
          badge={
            search?.categories.length
              ? search?.categories.length.toString()
              : null
          }
          updateSearch={updateSearch}
          error={error}
          Configurator={CategoryConfigurator}
        />
        <SearchMenuItem
          label="NAICS"
          icon="grid-2"
          search={search}
          refresh={refresh}
          badge={null}
          updateSearch={updateSearch}
          error={error}
          Configurator={NAICSConfigurator}
        />
        <SearchMenuItem
          label="Location"
          icon="location-dot"
          search={search}
          refresh={refresh}
          badge={
            search?.locations.length
              ? search?.locations.length.toString()
              : null
          }
          updateSearch={updateSearch}
          error={error}
          Configurator={LocationConfigurator}
        />
        <SearchMenuItem
          label="Customer"
          icon="user-group-simple"
          search={search}
          refresh={refresh}
          badge={
            search?.customers.length
              ? search?.customers.length.toString()
              : null
          }
          updateSearch={updateSearch}
          error={error}
          Configurator={CustomerConfigurator}
        />
        <SearchMenuItem
          label="Keywords"
          icon="text"
          search={search}
          refresh={refresh}
          badge={totalKeywords > 0 ? totalKeywords.toString() : null}
          updateSearch={updateSearch}
          error={error}
          Configurator={KeywordConfigurator}
        />
        <SearchMenuItem
          label="Save Search"
          icon="floppy-disk"
          search={search}
          refresh={refresh}
          variant={isSavedSearchDraft(search) ? "primary" : "default"}
          badge={null}
          updateSearch={updateSearch}
          error={error}
          Configurator={SavedSearchConfigurator}
        />
        <div className="grow">
          <ComboBox<SearchResult>
            inputIcon="magnifying-glass"
            options={options}
            renderOption={(option) => (
              <div className="flex flex-col">
                <span className="text-xs text-secondary">{option.details}</span>
                <span>{option.label}</span>
              </div>
            )}
            onSelected={(option) => {
              if (!search) return;
              onSelected(option, search, updateSearch);
            }}
            placeholder="Search"
            className="rounded-none rounded-r-sm border-l grow"
            activeClassName="rounded-none rounded-tr-sm outline bg-background drop-shadow-low"
            inputVariant="accent"
            onSearchChange={setSearchText}
          />
        </div>
      </Columns>
    </div>
  );
};

interface SearchMenuItemProps {
  label: string;
  icon: IconName;
  badge: string | boolean | null;
  variant?: "default" | "primary";
  search: SavedSearchDetail | null;
  updateSearch: (update: Partial<SavedSearchDetail>) => void;
  error?: unknown;
  Configurator: FC<SearchConfiguratorProps>;
  refresh: () => void;
}

const SearchMenuItem: FC<SearchMenuItemProps> = ({
  label,
  icon,
  variant = "default",
  badge,
  Configurator,
  search,
  updateSearch,
  error,
  refresh,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <PopoverTrigger>
        <Columns
          className={cn(
            "gap-sm items-center hover:bg-background-subtle px-sm whitespace-nowrap cursor-pointer relative overflow-visible group h-[39px] relative",
            isOpen && "bg-foreground text-background hover:bg-foreground",
            variant === "primary" && "text-primary"
          )}
        >
          <Icon
            name={icon}
            variant="solid"
            className="w-[30px] shrink-0 text-center flex items-center justify-center mr-thin"
          />
          {badge && (
            <div
              className={cn(
                "absolute rounded-full text-background top-[70%] left-[55%] text-xs px-xs min-w-[17px] min-h-[17px] text-center",
                badge === true ? "bg-primary" : "bg-ring"
              )}
            >
              {badge}
            </div>
          )}
        </Columns>
        {/* <div className="absolute top-full z-[9999] opacity-0 group-hover:opacity-100 transition-all bg-background p-md">
        <h1 className="text-lg font-semibold">{label}</h1>
      </div> */}
      </PopoverTrigger>
      <PopoverContent sideOffset={10} side="bottom" align="start">
        <PopoverArrow className="fill-border" />
        <h1 className="text-lg font-semibold">{label}</h1>
        <AsyncLoadedDiv
          value={search}
          error={error ? message_from_exception(error) : undefined}
          whileLoaded={(search) => {
            return (
              <Configurator
                search={search}
                updateSearch={updateSearch}
                close={() => setIsOpen(false)}
                refresh={refresh}
              />
            );
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export default RFPSearchMenu;
