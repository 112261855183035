import Icon from "components/common/Icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import { useConfirm, useTextAreaConfirm } from "providers/AlertProvider";
import { FC } from "react";

interface ListActionsButtonProps {
  listName: string;
  deleteList: () => void;
  renameList: (newName: string) => void;
}

const ListActionsButton: FC<ListActionsButtonProps> = ({
  listName,
  deleteList,
  renameList,
}) => {
  const confirm = useConfirm();
  const textAreaConfirm = useTextAreaConfirm();
  const handleDeleteList = async () => {
    const confirmed = await confirm(
      `Are you sure you want to delete ${listName}?`,
      {
        body: (
          <>
            <p>
              This action cannot be undone but you'll still be able to find the
              RFPs in our system.
            </p>
          </>
        ),
        yesText: "Delete Permanently",
        yesDestructive: true,
        noText: "Cancel",
      }
    );
    if (confirmed) {
      deleteList();
    }
  };

  const handleRenameList = async () => {
    const result = await textAreaConfirm("Rename List", {
      placeholder: "Enter the new name for the list",
      initialText: listName,
      multiline: false,
      buttons: [
        {
          text: "Cancel",
          id: "cancel",
        },
        {
          text: "Rename",
          id: "rename",
          variant: "solid",
        },
      ],
    });
    if (result.id === "rename") {
      renameList(result.text);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="px-2m py-sm -ml-xs hover:opacity-50">
        <Icon name="ellipsis-vertical" className="text-secondary" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem icon="pen" onClick={handleRenameList}>
          Rename List
        </DropdownMenuItem>
        <DropdownMenuItem icon="trash" onClick={handleDeleteList}>
          Delete List
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ListActionsButton;
