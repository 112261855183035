import { FC, useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import { cn } from "lib/utils";
import { odoToast } from "lib/odoToast";

interface KeyTermsInputProps {
  value: string[];
  onChange: (value: string[]) => void;
}

/**
 * A component that allows the users to input multiple keywords
 *
 * It acts as a multiline input but if the user hits enter or comma, it saves out the
 * current pending text as a pill. Each pill has a clear button to remove it.
 */
const KeyTermsInput: FC<KeyTermsInputProps> = ({ value, onChange }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const newTermRef = useRef<HTMLParagraphElement>(null);

  const saveTerm = (text: string): boolean => {
    if (text.length === 0) return false;

    if (value.includes(text)) {
      odoToast.error({
        title: "Keyword already exists",
        text: "Please enter a unique keyword",
      });
      return false;
    }

    onChange([...value, text]);
    return true;
  };

  const handleNewTermKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const div = event.target as HTMLDivElement;
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    const isAtStart = range && range.collapsed && range.startOffset === 0;
    if (["Enter", "Tab", ","].includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
      const text = div.textContent?.trim();
      if (text) {
        if (saveTerm(text)) {
          div.textContent = "";
        }
      }
    } else if (event.key === "ArrowLeft") {
      if (isAtStart) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(value.length - 1);
        div.blur();
      }
    } else if (event.key === "Backspace") {
      if (isAtStart && value.length > 0) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(value.length - 1);
        div.blur();
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedIndex === null) return;

      if (["Backspace", "Delete"].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
        if (selectedIndex !== null) {
          onChange(value.filter((_, index) => index !== selectedIndex));
          if (selectedIndex === 0) {
            setSelectedIndex(null);
            newTermRef.current?.focus();
          } else {
            setSelectedIndex(Math.max(0, selectedIndex - 1));
          }
        }
      } else if (event.key === "ArrowLeft") {
        event.preventDefault();
        event.stopPropagation();
        if (selectedIndex !== null) {
          setSelectedIndex(Math.max(0, selectedIndex - 1));
        }
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        event.stopPropagation();
        if (selectedIndex !== null) {
          if (selectedIndex === value.length - 1) {
            setSelectedIndex(null);
            newTermRef.current?.focus();
          } else {
            setSelectedIndex(selectedIndex + 1);
          }
        }
      } else if (event.key === "Tab") {
        if (selectedIndex === value.length - 1) {
          event.preventDefault();
          newTermRef.current?.focus();
        } else {
          event.preventDefault();
          setSelectedIndex(selectedIndex + 1);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onChange, selectedIndex, value]);

  const pillClassName =
    "inline-block px-sm rounded-md m-xs bg-background-secondary";

  return (
    <div
      className="border rounded-md p-xs"
      onClick={() => newTermRef.current?.focus()}
    >
      {value.map((term, index) => (
        <p
          key={term}
          className={cn(
            pillClassName,
            selectedIndex === index && "bg-primary text-background"
          )}
          onClick={(e) => {
            e.stopPropagation();
            newTermRef.current?.blur();
            setSelectedIndex(index);
          }}
        >
          {term}
          <Icon
            name="circle-xmark"
            className={cn(
              "text-secondary ml-xs",
              selectedIndex === index
                ? "hover:text-background"
                : "hover:text-foreground"
            )}
            onClick={(e) => {
              e.stopPropagation();
              onChange(value.filter((_, i) => i !== index));
            }}
          />
        </p>
      ))}
      <p
        ref={newTermRef}
        onFocus={() => setSelectedIndex(null)}
        className={cn(
          pillClassName,
          "focus:bg-primary focus:text-background min-w-[120px]"
        )}
        placeholder="New keyword"
        contentEditable={true}
        onKeyDown={handleNewTermKeyDown}
      />
    </div>
  );
};

export default KeyTermsInput;
