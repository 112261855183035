import { SavedSearchDetail } from "api/Api";
import { ResultBase } from "components/common/menus/ComboBox";
import { useCallback, useMemo } from "react";

export const isSavedSearchDraft = (search: SavedSearchDetail | null) =>
  typeof search?.draft_for_user_id === "number";

export interface SearchConfiguratorProps {
  search: SavedSearchDetail;
  updateSearch: (
    update: Partial<SavedSearchDetail>,
    saveToRemote?: boolean
  ) => void;
  close: () => void;
  refresh: () => void;
}

export interface SearchResult extends ResultBase {
  label: string;
  details?: string;
}

interface SearchResultsGeneratorOutput {
  options: SearchResult[];
  onSelected?: (
    option: SearchResult,
    search: SavedSearchDetail,
    updateSearch: (update: Partial<SavedSearchDetail>) => void
  ) => void;
}
export type SearchResultsGenerator = (
  searchText: string,
  search: SavedSearchDetail | null
) => SearchResultsGeneratorOutput;

export const useAggregatedSearchResults = (
  outputs: { output: SearchResultsGeneratorOutput; id: string }[]
) => {
  const allOptions = useMemo(() => {
    return outputs.flatMap(({ output, id }) =>
      output.options.map((o) => ({ ...o, id: id + "_" + o.id }))
    );
  }, [outputs]);

  const onSelected = useCallback(
    (
      option: SearchResult,
      search: SavedSearchDetail,
      updateSearch: (update: Partial<SavedSearchDetail>) => void
    ) => {
      const id = option.id.split("_")[0];
      const output = outputs.find((o) => o.id === id);
      output?.output.onSelected?.(option, search, updateSearch);
    },
    [outputs]
  );

  return { options: allOptions, onSelected };
};
