import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import LoadableView from "components/common/containers/LoadableView";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import { FileInputRef } from "components/common/forms/FileInput";
import MultiFileInput from "components/common/forms/MultiFileInput";
import Icon from "components/common/Icon";
import Spinner from "components/common/Spinner";
import useFileHash from "hooks/useFileHash";
import { useApiClient } from "providers/ApiClientProvider";
import { useRef, useState } from "react";

interface AddPastProposalOverlayProps {
  onClose: () => void;
}

const AddPastProposalOverlay: React.FC<AddPastProposalOverlayProps> = ({
  onClose,
}) => {
  const [isFileUploading] = useState(false);
  const fileInputRef = useRef<FileInputRef>(null);
  const hashFile = useFileHash();
  const apiClient = useApiClient();

  return (
    <Overlay
      title="Add Past Proposal"
      className="w-[400px]"
      maxWidth={600}
      onClose={onClose}
    >
      <LoadableView isLoading={isFileUploading}>
        <Rows className="gap-md">
          <MultiFileInput<{ id: string; name: string }>
            validFileTypes={["application/pdf"]}
            ref={fileInputRef}
            processFile={async (file) => {
              if (!file) return { id: "no-file", name: "No file found" };

              const name = file.name.replace(/\.[^/.]+$/, "");
              const hash = await hashFile(file);

              // Create the record
              const createResponse = await apiClient.rfp.rfpProposalPastCreate({
                hash,
                name,
              });

              // Upload the file
              await fetch(createResponse.data.upload_url!, {
                method: "PUT",
                body: file,
              });

              // Confirm the upload
              await apiClient.rfp.rfpProposalPastConfirmCreate(
                createResponse.data.id!
              );

              return {
                id: createResponse.data.id!,
                name: file.name,
              };
            }}
            renderFile={(file) => {
              return (
                <Columns className="items-center gap-md">
                  <Icon name="check" />
                  {file.name}
                </Columns>
              );
            }}
            renderPendingFile={(file) => {
              return (
                <Columns className="items-center gap-md">
                  <Spinner size="inherit" />
                  {file.name}
                </Columns>
              );
            }}
          />
          <div className="flex justify-end">
            <Button variant="solid" text="Done" onClick={onClose} />
          </div>
        </Rows>
      </LoadableView>
    </Overlay>
  );
};

export default AddPastProposalOverlay;
