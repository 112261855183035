import Rows from "components/common/containers/Rows";
import {
  isSavedSearchDraft,
  SearchConfiguratorProps,
} from "./SearchConfiguratorProps";
import Button from "components/common/Button";
import { useAlert, useConfirm } from "providers/AlertProvider";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Icon from "components/common/Icon";
import { SavedSearchList } from "api/Api";

const SwitchSearchConfig = ({
  search,
  updateSearch,
  close,
  refresh,
}: SearchConfiguratorProps) => {
  const confirm = useConfirm();
  const alert = useAlert();
  const apiClient = useApiClient();
  const [savedSearches, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpSavedSearchList,
    map: (data) => data,
  });

  const handleChoose = async (id: number) => {
    if (id === search.id) {
      alert("You're already on this search");
      return;
    }

    if (isSavedSearchDraft(search)) {
      const result = await confirm(
        `Switch to ${search.name ?? "this search"}?`,
        {
          body: "This will discard your unsaved search changes",
          yesText: "Discard Unsaved Changes",
          noText: "Cancel",
        }
      );
      if (result !== true) return;
    }

    const response = await apiClient.rfp.rfpSavedSearchChooseCreate(
      id.toString(),
      {}
    );
    updateSearch(response.data, false);
  };

  const handleDeleteDraft = async (toDelete: SavedSearchList) => {
    const result = await confirm(
      `Are you sure you want to delete ${search.name ?? "this draft"}?`,
      {
        yesText: "Yes, delete",
      }
    );
    if (result !== true) return;

    await apiClient.rfp.rfpSavedSearchDelete(toDelete.id.toString());
    if (search.id === toDelete.id) {
      refresh();
    }
    close();
  };

  return (
    <Rows className="gap-md pt-sm">
      <p className="text-sm text-secondary">
        {savedSearches?.length === 0
          ? "You can save filters so you can easily access them later."
          : "Switch between your team's saved searches"}
      </p>
      <PaginatedTableView
        results={savedSearches}
        paginatedData={paginatedData}
        columns={[
          { size: isSavedSearchDraft(search) ? 0 : 32 },
          {},
          { size: "min" },
        ]}
        onSelect={(search) => handleChoose(search.id)}
        renderRow={(element, Cell, Row) => (
          <Row key={element.id}>
            <Cell className="bg-background-popover">
              {element.id === search.id && (
                <Icon name="check" className="text-primary" />
              )}
            </Cell>
            <Cell className="bg-background-popover">
              {element.display_name}
            </Cell>
            <Cell className="bg-background-popover">
              {element.is_default !== true && (
                <Button
                  icon="trash"
                  onClick={() => handleDeleteDraft(element)}
                />
              )}
            </Cell>
          </Row>
        )}
      />
    </Rows>
  );
};

export default SwitchSearchConfig;
