import { RFPSearch } from "api/Api";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { useState } from "react";
import NavigationView from "components/common/NavigationView";
import RFPListItemView from "components/rfps/RFPListItemView";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import Overlay from "components/common/containers/overlays/Overlay";
import FullRFPView from "components/rfps/FullRFPView";
import Button from "components/common/Button";
import RFPSearchMenu from "components/rfps/search-menu/RFPSearchMenu";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import { ShortListsProvider } from "providers/ShortListsProvider";
import { copyToClipboard } from "lib/clipboard";

const ExploreRFPsRoute = () => {
  const apiClient = useApiClient();
  const [rfps, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpRfpSearchList,
    mode: "pages",
    map: (data) => data,
    deps: [],
  });
  const [viewingRFP, setViewingRFP] = useState<RFPSearch | null>(null);

  const viewDetails = (rfp: RFPSearch) => {
    setViewingRFP(rfp);
  };

  const copyLink = (rfp: RFPSearch) => {
    copyToClipboard(`${window.location.origin}/rfps/${rfp.id}/`);
  };

  return (
    <ShortListsProvider>
      <NavigationView selected="explore" fullWidth={true} scrollable={false}>
        <Columns className="flex gap-md justify-center grow">
          <Rows className="grow overflow-hidden max-w-[800px]">
            <RFPSearchMenu
              onUpdated={() => {
                // Refresh the data
                paginatedData.refresh();
              }}
            />
            <PaginatedTableView
              results={rfps}
              paginatedData={paginatedData}
              columns={[]}
              className="grow"
              scrollableClassName="border-0"
              renderRow={(rfp, Cell, Row) => (
                <Row key={rfp.id}>
                  <Cell className="px-sm">
                    <RFPListItemView
                      rfp={rfp}
                      onViewDetails={() => viewDetails(rfp)}
                      intent="shortlist"
                    />
                  </Cell>
                </Row>
              )}
            />
          </Rows>
        </Columns>
        {viewingRFP && (
          <Overlay
            title={viewingRFP.display_name}
            extraTitleBarElements={
              <Button
                icon="link-simple"
                className="ml-sm"
                onClick={() => copyLink(viewingRFP)}
              />
            }
            variant="bordered"
            onClose={() => setViewingRFP(null)}
          >
            <FullRFPView rfp={viewingRFP} intent="shortlist" />
          </Overlay>
        )}
      </NavigationView>
    </ShortListsProvider>
  );
};

export default ExploreRFPsRoute;
