import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { getLibraryItemInfo } from "components/content-library/ContentLibraryListItem";
import { Category } from "hooks/useContentLibrary";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { FC } from "react";
import { message_from_exception } from "utils";

interface UserChoicesPanelProps {}

const UserChoicesPanel: FC<UserChoicesPanelProps> = () => {
  const { details } = useProposalData();

  return (
    <Scrollable>
      <Rows className="grow p-md gap-sm">
        <LabeledFormInput label="Strategy">
          {details.instructions || "None"}
        </LabeledFormInput>
        <LibraryItemDetails
          label="Subcontractors"
          proposalId={details.id}
          category="subcontractor"
        />
        <LibraryItemDetails
          label="Staffing"
          proposalId={details.id}
          category="person"
        />
        <LibraryItemDetails
          label="References"
          proposalId={details.id}
          category="project"
        />
        <LibraryItemDetails
          label="Past Proposal"
          proposalId={details.id}
          category="past_proposal"
        />
      </Rows>
    </Scrollable>
  );
};

interface LibraryItemDetailsProps {
  label: string;
  proposalId: string;
  category: Category | "past_proposal";
}

const LibraryItemDetails: FC<LibraryItemDetailsProps> = ({
  label,
  proposalId,
  category,
}) => {
  const apiClient = useApiClient();
  const [items, , { error }] = useFetchedData(async () => {
    if (category === "past_proposal") {
      const response = await apiClient.rfp.rfpProposalPastList(proposalId, {
        // @ts-ignore
        proposal_id: proposalId,
        associated_only: true,
      });
      return response.data.results;
    }
    const response = await apiClient.rfp.rfpContentLibrarySearchCreate({
      proposal_id: proposalId,
      associated_only: true,
      kind: category,
    });
    return response.data.results;
  }, [proposalId]);
  return (
    <LabeledFormInput label={label}>
      <AsyncLoadedDiv
        value={items}
        error={error ? message_from_exception(error) : undefined}
        whileLoaded={(items) => (
          <ul className="list-disc">
            {items.map((item) => {
              const info = getLibraryItemInfo(item);
              return (
                <li className="ml-lg" key={item.id}>
                  {info.title}
                  {info.subtitle && (
                    <>
                      <br />
                      <span className="text-secondary">{info.subtitle}</span>
                    </>
                  )}
                </li>
              );
            })}
            {items.length === 0 && <p>None</p>}
          </ul>
        )}
      />
    </LabeledFormInput>
  );
};

export default UserChoicesPanel;
