import { FC, ImgHTMLAttributes } from "react";
import { cn } from "lib/utils";
import { VariantProps, cva } from "class-variance-authority";

export type IconName =
  | "address-card"
  | "align-justify"
  | "angle-up"
  | "arrow-left"
  | "arrow-left-to-bracket"
  | "arrow-right"
  | "arrow-right-from-bracket"
  | "arrow-right-to-bracket"
  | "arrow-up-right-from-square"
  | "back"
  | "bars"
  | "ballot-check"
  | "block-quote"
  | "bold"
  | "bolt-lightning"
  | "book-open-cover"
  | "box-archive"
  | "brain-circuit"
  | "briefcase"
  | "broom-wide"
  | "buildings"
  | "bullseye"
  | "calendar"
  | "check"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevron-up"
  | "clipboard-list"
  | "circle"
  | "circle-check"
  | "circle-half-stroke"
  | "circle-info"
  | "circle-minus"
  | "circle-envelope"
  | "circle-exclamation"
  | "circle-question"
  | "circle-t"
  | "city"
  | "circle-xmark"
  | "clipboard-list"
  | "clone"
  | "code"
  | "comment-check"
  | "comment-pen"
  | "comment-plus"
  | "comment-xmark"
  | "comment"
  | "compass"
  | "copy"
  | "chart-line-up"
  | "dash"
  | "diagram-subtask"
  | "do-not-enter"
  | "download"
  | "ellipsis-vertical"
  | "ellipsis"
  | "envelope"
  | "envelope-open-text"
  | "envelopes-bulk"
  | "eye"
  | "eyes"
  | "eye-slash"
  | "file-arrow-up"
  | "file-check"
  | "file-excel"
  | "file-export"
  | "file-lines"
  | "file-pdf"
  | "file-plus"
  | "file-slash"
  | "file-signature"
  | "file-word"
  | "file"
  | "filter"
  | "flask-vial"
  | "flask"
  | "floppy-disk"
  | "folder-arrow-up"
  | "gear"
  | "gift"
  | "globe"
  | "grid-2"
  | "graduation-cap"
  | "grip-lines"
  | "hand-holding-heart"
  | "highlighter-line"
  | "home"
  | "hourglass-half"
  | "hospital"
  | "hyphen"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "image"
  | "indent"
  | "inbox"
  | "inbox-in"
  | "island-tropical"
  | "italic"
  | "landmark"
  | "layer-group"
  | "layer-plus"
  | "leaf"
  | "location-dot"
  | "lock"
  | "lightbulb-on"
  | "link-simple-slash"
  | "link-simple"
  | "list"
  | "list-check"
  | "list-ol"
  | "list-tree"
  | "list-ul"
  | "magnifying-glass"
  | "megaphone"
  | "memo-circle-info"
  | "octagon-xmark"
  | "outdent"
  | "phone"
  | "paper-plane-top"
  | "paragraph"
  | "pen"
  | "people-group"
  | "period"
  | "person"
  | "person-chalkboard"
  | "person-running-fast"
  | "play"
  | "plus"
  | "rectangle-code"
  | "refresh"
  | "right-left"
  | "robot"
  | "scale-unbalanced"
  | "share"
  | "signal-bars-slash"
  | "skull"
  | "slack"
  | "sparkles"
  | "square-check"
  | "square"
  | "square-exclamation"
  | "square-left"
  | "square-question"
  | "star"
  | "strikethrough"
  | "square-list"
  | "square-plus"
  | "table-columns"
  | "table-rows"
  | "table"
  | "tag"
  | "text"
  | "thought-bubble"
  | "toggle-large-off"
  | "toggle-large-on"
  | "trash"
  | "trash-clock"
  | "trash-undo"
  | "triangle-person-digging"
  | "turn-down-left"
  | "turn-down-right"
  | "typewriter"
  | "rotate-left"
  | "sitemap"
  | "underline"
  | "user"
  | "user-group-simple"
  | "vials"
  | "wifi-slash"
  | "xmark";

const iconVariants = cva("", {
  variants: {
    variant: {
      DEFAULT: "fa-regular",
      light: "fa-light",
      solid: "fa-solid",
      brands: "fa-brands",
    },
    size: {
      DEFAULT: "",
      large: "text-2xl",
      xlarge: "text-3xl",
      huge: "text-[50px]",
      medium: "text-md",
      small: "text-sm",
      xsmall: "text-sm",
      tiny: "text-[6px]",
    },
  },
  defaultVariants: {
    variant: "DEFAULT",
    size: "DEFAULT",
  },
});

export interface IconProps
  extends ImgHTMLAttributes<HTMLImageElement>,
    VariantProps<typeof iconVariants> {
  name: IconName;
}

const Icon: FC<IconProps> = ({
  name,
  alt,
  size,
  variant,
  className,
  ...props
}) => {
  return (
    <i
      className={cn(iconVariants({ size, variant }), `fa-${name}`, className)}
      draggable={false}
      {...props}
    />
  );
};

export default Icon;
