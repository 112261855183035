import { useMemo } from "react";
import {
  SearchConfiguratorProps,
  SearchResultsGenerator,
} from "./SearchConfiguratorProps";
import {
  ALL_TYPES,
  CustomerType,
  CustomerTypePicker,
} from "components/profile/CustomerTypePicker";

const CustomerConfigurator = ({
  search,
  updateSearch,
}: SearchConfiguratorProps) => {
  return (
    <CustomerTypePicker
      values={search.customers}
      onChange={(values) =>
        updateSearch({
          customers: values,
        })
      }
    />
  );
};

const FLAT_TYPES = ALL_TYPES.flatMap(({ displayName, types, icon }) =>
  types.map((t) => ({
    ...t,
    category: displayName,
    displayName: t.displayName,
    icon,
  }))
);

const mapCustomerTypeToSearchResult = (type: CustomerType) => {
  return {
    id: type.id,
    label: type.pluralName,
    details: "Include RFPs issued by",
    category: "Customer",
  };
};
export const useCustomerSearchResults: SearchResultsGenerator = (
  searchText
) => {
  const options = useMemo(() => {
    if (searchText.length === 0) {
      return FLAT_TYPES.slice(0, 3).map(mapCustomerTypeToSearchResult);
    }

    return FLAT_TYPES.filter(({ displayName, category, pluralName }) => {
      return (
        category.toLowerCase().includes(searchText.toLowerCase()) ||
        displayName.toLowerCase().includes(searchText.toLowerCase()) ||
        pluralName.toLowerCase().includes(searchText.toLowerCase())
      );
    }).map(mapCustomerTypeToSearchResult);
  }, [searchText]);

  return {
    options,
    onSelected: (option, search, updateSearch) => {
      if (search.customers.includes(option.id)) {
        updateSearch({
          customers: search.customers.filter((id) => id !== option.id),
        });
      } else {
        updateSearch({
          customers: [...search.customers, option.id],
        });
      }
    },
  };
};

export default CustomerConfigurator;
