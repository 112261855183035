import Button from "components/common/Button";
import { cn, format_absolute_date } from "lib/utils";
import { FC, useRef } from "react";
import { usePostHog } from "posthog-js/react";
import Columns from "components/common/containers/Columns";
import { RFPSearch } from "api/Api";
import RFPOptionsView, { RFPIntent } from "./RFPOptionsView";
import { useListsForRFP } from "providers/ShortListsProvider";
import { useIsMetaKeyDown } from "hooks/useIsKeyDown";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { copyToClipboard } from "lib/clipboard";

interface RFPListItemViewProps {
  rfp: RFPSearch;
  showSelected?: boolean;
  onViewDetails: () => void;
  intent: RFPIntent;
  primaryListId?: string;
}

const RFPListItemView: FC<RFPListItemViewProps> = ({
  rfp,
  onViewDetails,
  intent,
  showSelected = true,
  primaryListId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const posthog = usePostHog();
  const { inLists } = useListsForRFP(rfp.id);
  const { favoriteListId } = useAuthenticatedUser();
  const isMetaKeyDown = useIsMetaKeyDown();

  const handleViewDetails = () => {
    if (isMetaKeyDown) {
      // Open the details in a new tab
      window.open(`/rfps/${rfp.id}/`, "_blank");
      return;
    }
    onViewDetails();
    try {
      posthog.capture("rfp_viewed_details", { rfp_slug: rfp.id });
    } catch (e) {
      console.error("Error capturing posthog event", e);
    }
  };

  const copyLink = (rfp: RFPSearch) => {
    copyToClipboard(`${window.location.origin}/rfps/${rfp.id}/`);
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        "rounded-md flex flex-col tablet:flex-row gap-md overflow-hidden border relative transition-opacity",
        Object.keys(inLists).length > 0 && "opacity-50"
      )}
    >
      <div className="tablet:w-[140px] items-center text-center tablet:items-end tablet:flex tablet:flex-col shrink-0 bg-background-secondary p-md gap-sm">
        <div className="grow tablet:hidden" />
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <p className="text-secondary text-xs">Posted</p>
          <p className="text-sm font-semibold">
            {format_absolute_date(rfp.created)}
          </p>
        </div>
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <p className="text-secondary text-xs">Proposal Due</p>
          <p className="text-sm font-semibold">
            {format_absolute_date(rfp.due_date)}
          </p>
        </div>
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <span className="text-secondary text-xs">Budget </span>
          <div className="vertical-text-elipsis text-right">
            {rfp.budget ? rfp.budget : "-"}
          </div>
        </div>
        <div className="mx-md inline-flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <span className="text-secondary text-xs">Location </span>
          <div className="vertical-text-elipsis text-right text-sm">
            {rfp.location ? rfp.location : "-"}
          </div>
        </div>
        <div className="grow" />
      </div>
      <div className="grow flex flex-col gap-sm max-w-[800px] p-md relative">
        <Columns className="font-semibold text-lg group items-center gap-xs">
          {rfp.display_name}
          <Button
            icon="link-simple"
            size="small"
            className="opacity-0 group-hover:opacity-100"
            onClick={() => copyLink(rfp)}
          />
        </Columns>
        <p className="text-secondary text-md">{rfp.issuing_org}</p>
        <p className="text-sm">{rfp.description}</p>
        <div className="flex gap-sm mt-md">
          <Button
            text="View Details"
            variant="solid-secondary"
            className="hidden tablet:block text-sm px-sm"
            onClick={handleViewDetails}
          />
          <Button
            text="Details"
            variant="solid-secondary"
            size="small"
            className="tablet:hidden text-sm px-sm"
            onClick={handleViewDetails}
          />
          <div className="grow" />
          <RFPOptionsView
            rfp={rfp}
            intent={intent}
            primaryListId={primaryListId}
          />
        </div>
      </div>
    </div>
  );
};

export default RFPListItemView;
