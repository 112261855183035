import useYjsMap from "api/useYjsMap";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAuthenticatedUser } from "./AuthenticatedUserProvider";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { get_hub_url } from "api/env";

import * as Y from "yjs";
import { useNavigate } from "react-router-dom";
import { odoToast } from "lib/odoToast";

export interface ShortList {
  name: string;
  rfpIds: Record<string, boolean>;
}

interface ShortListsProviderData {
  lists: Record<string, ShortList>;
  deleteList: (listId: string) => void;
  addRFPToList: (rfpId: string, listName: string) => void;
  addRFPToNewList: (rfpId: string, listName: string) => void;
  removeRFPFromList: (rfpId: string, listName: string) => void;
  renameList: (listId: string, newName: string) => void;
  addNewList: (listName: string) => string;
  connectionStatus: // The initial status, when the connection is first connecting
  | "connecting"
    // The connection is established and the lists are being synced
    | "connected"
    // The connection is lost and the lists are being reconnected
    // (But the initial data has been loaded)
    | "reconnecting";
}

const ShortListsContext = createContext<ShortListsProviderData | null>(null);

export const DEFAULT_LIST: ShortList = {
  name: "List 1",
  rfpIds: {},
};

export const ShortListsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const providerRef = useRef<HocuspocusProvider | null>(null);
  const { orgId } = useAuthenticatedUser();
  const navigate = useNavigate();
  const [providerStatus, setProviderStatus] = useState<
    "connected" | "connecting" | "disconnected"
  >("connecting");
  const [hasLoadedInitialData, setHasLoadedInitialData] = useState(false);
  const [overallStatus, setOverallStatus] =
    useState<ShortListsProviderData["connectionStatus"]>("connecting");

  const [lists, setLists] = useYjsMap<ShortList>(
    providerRef.current,
    "short-lists",
    {
      [listNameToId(DEFAULT_LIST.name)]: { ...DEFAULT_LIST },
    },
    true
  );

  useEffect(() => {
    const doc = new Y.Doc();
    const provider = new HocuspocusProvider({
      url: get_hub_url("ws", ""),
      name: "org-" + orgId,
      token: "insufficient-secret",
      document: doc,
      preserveConnection: false,
      awareness: null,
    });

    provider.on("status", ({ status }: { status: string }) => {
      setProviderStatus(status as any);
      switch (status) {
        case "connected":
          setHasLoadedInitialData(true);
          break;
      }
    });

    providerRef.current = provider;

    return () => {
      provider.destroy();
    };
  }, [orgId]);

  useEffect(() => {
    switch (providerStatus) {
      case "connected":
        setOverallStatus("connected");
        break;
      case "connecting":
        if (hasLoadedInitialData) {
          setOverallStatus("reconnecting");
        } else {
          setOverallStatus("connecting");
        }
        break;
      case "disconnected":
        if (hasLoadedInitialData) {
          setOverallStatus("reconnecting");
        } else {
          setOverallStatus("connecting");
        }
        break;
    }
  }, [providerStatus, hasLoadedInitialData]);

  const addRFPToList = useCallback(
    (rfpId: string, listId: string) => {
      const list = { ...lists[listId] };
      list.rfpIds[rfpId] = true;
      setLists(listId, list);
      odoToast.success({
        title: "RFP Added",
        text: `This RFP can now be found on ${list.name}`,
        cta: {
          text: "View List",
          onClick: () => {
            navigate(`/lists/${listId}/`);
          },
          undo: {
            text: "Back",
            onClick: () => {
              navigate(-1);
            },
          },
        },
      });
    },
    [lists, navigate, setLists]
  );

  const addRFPToNewList = useCallback(
    (rfpId: string, listName: string) => {
      const listId = listNameToId(listName);
      setLists(listId, {
        ...DEFAULT_LIST,
        name: listName,
        rfpIds: { [rfpId]: true },
      });
      odoToast.success({
        title: "RFP Added",
        text: `This RFP can now be found on ${listName}`,
        cta: {
          text: "View List",
          onClick: () => {
            navigate(`/lists/${listId}/`);
          },
        },
      });
    },
    [setLists, navigate]
  );

  const addNewList = useCallback(
    (listName: string) => {
      const listId = listNameToId(listName);
      setLists(listId, { ...DEFAULT_LIST, name: listName });
      odoToast.success({
        title: "List Created",
        text: `A list named ${listName} has been created`,
        cta: {
          text: "View List",
          onClick: () => {
            navigate(`/lists/${listId}/`);
          },
        },
      });
      return listId;
    },
    [setLists, navigate]
  );

  const renameList = useCallback(
    (listId: string, newName: string) => {
      const list = { ...lists[listId] };
      const originalName = list.name;
      list.name = newName;
      const newId = listNameToId(newName);
      setLists(newId, list);
      setLists(listId, null);
      odoToast.success({
        title: "List Renamed",
        text: `This list has been renamed to ${newName}`,
        cta: {
          text: "Undo",
          onClick: () => {
            setLists(newId, null);
            setLists(listId, { ...list, name: originalName });
          },
        },
      });
    },
    [lists, setLists]
  );

  const removeRFPFromList = useCallback(
    (rfpId: string, listId: string) => {
      const list = { ...lists[listId] };
      delete list.rfpIds[rfpId];
      setLists(listId, list);
      odoToast.warning({
        title: "RFP Removed",
        text: `This RFP can no longer be found on ${list.name}`,
        cta: {
          text: "Undo",
          onClick: () => {
            addRFPToList(rfpId, listId);
          },
        },
      });
    },
    [lists, setLists, addRFPToList]
  );

  const deleteList = useCallback(
    (listId: string) => {
      const originalList = lists[listId];
      setLists(listId, null);
      odoToast.warning({
        title: "List Deleted",
        text: "This list has been deleted",
        cta: {
          text: "Undo",
          onClick: () => {
            setLists(listId, originalList);
          },
        },
      });
    },
    [lists, setLists]
  );

  return (
    <ShortListsContext.Provider
      value={{
        lists,
        addRFPToList,
        addRFPToNewList,
        addNewList,
        removeRFPFromList,
        renameList,
        deleteList,
        connectionStatus: overallStatus,
      }}
    >
      {children}
    </ShortListsContext.Provider>
  );
};

export const useShortLists = () => {
  const data = useContext(ShortListsContext);
  if (!data) {
    throw new Error("ShortListsProvider not found");
  }
  return data;
};

export const useListsForRFP = (rfpId: string) => {
  const {
    lists,
    addRFPToList,
    removeRFPFromList,
    addRFPToNewList,
    connectionStatus,
    addNewList,
  } = useShortLists();

  let inLists: Record<string, ShortList> = {};
  for (const [id, list] of Object.entries(lists)) {
    if (rfpId in list.rfpIds) {
      inLists[id] = list;
    }
  }

  return {
    allLists: lists,
    inLists,
    addRFPToList: (listId: string) => addRFPToList(rfpId, listId),
    removeRFPFromList: (listId: string) => removeRFPFromList(rfpId, listId),
    addRFPToNewList: (listName: string) => addRFPToNewList(rfpId, listName),
    addNewList,
    connectionStatus,
  };
};

/**
 * Convert a list name to a list id
 *
 * This just makes it lower case to disallow multiple lists
 * only different by their casing
 */
export const listNameToId = (listName: string) => {
  return listName
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^a-zA-Z0-9]/g, "_");
};
