import StatePicker, { State, STATES } from "components/profile/StatePicker";
import {
  SearchConfiguratorProps,
  SearchResultsGenerator,
} from "./SearchConfiguratorProps";
import { useMemo } from "react";

const LocationConfigurator = ({
  search,
  updateSearch,
}: SearchConfiguratorProps) => {
  return (
    <StatePicker
      stateLocations={search.locations}
      setStateLocations={(states) => {
        if (typeof states === "function") {
          updateSearch({
            locations: states(search.locations ?? []),
          });
        } else {
          updateSearch({
            locations: states,
          });
        }
      }}
    />
  );
};

const mapStateToSearchResult = (state: State) => {
  return {
    id: state.abbrevation,
    label: state.name,
    details: "Only show RFPs in",
    category: "Location",
  };
};

export const useLocationSearchResults: SearchResultsGenerator = (
  searchText
) => {
  const options = useMemo(() => {
    if (searchText.length === 0) {
      return STATES.slice(0, 3).map(mapStateToSearchResult);
    }

    return STATES.filter((state) => {
      return (
        state.abbrevation.toLowerCase() === searchText.toLowerCase() ||
        state.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }).map(mapStateToSearchResult);
  }, [searchText]);

  return {
    options,
    onSelected: (option, search, updateSearch) => {
      if (search.locations.includes(option.id)) {
        updateSearch({
          locations: search.locations.filter((id) => id !== option.id),
        });
      } else {
        updateSearch({
          locations: [...search.locations, option.id],
        });
      }
    },
  };
};

export default LocationConfigurator;
