import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import RFPPDFView from "components/rfps/RFPPDFView";
import { useAlert } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { FC } from "react";
import { odoToast } from "lib/odoToast";

interface RFPCoveragePanelProps {}

const RFPCoveragePanel: FC<RFPCoveragePanelProps> = () => {
  const { details } = useProposalData();
  const alert = useAlert();
  const { isWriter } = useAuthenticatedUser();
  const apiClient = useApiClient();

  const handleInfoClick = () => {
    alert("RFP Coverage", {
      body: (
        <Rows className="gap-md">
          <p>
            Review what parts of the RFP are covered by the proposal outline.
          </p>
          <p>
            Covered parts of the RFP will be highlighted in green, while
            requirements from the RFP that are not covered will be highlighted
            in red.
          </p>
          <p>
            You can also drag any content from the RFP to the proposal to ensure
            it gets covered.
          </p>
        </Rows>
      ),
    });
  };

  const handleRefresh = async () => {
    try {
      await apiClient.rfp.rfpProposalRefreshComplianceCreate(details.id);
      odoToast.success({
        title: "Coverage Refresh",
        text: "This will take a while",
      });
    } catch (e) {
      odoToast.caughtError(e, "Refreshing Coverage");
    }
  };

  return (
    <Rows className="grow px-md pt-md gap-md">
      <Columns className="items-center gap-sm">
        <h1 className="text-lg font-semibold">RFP</h1>
        {/* <Button icon="circle-info" onClick={handleInfoClick} /> */}
        {isWriter && (
          <Button
            icon="refresh"
            className="mr-md admin text-primary"
            tooltip="Rerun RFP Coverage"
            onClick={handleRefresh}
          />
        )}
      </Columns>
      <RFPPDFView rfpId={details.rfpId} />
    </Rows>
  );
};

export default RFPCoveragePanel;
