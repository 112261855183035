import { useApiClient } from "providers/ApiClientProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePaginatedData from "hooks/usePaginatedData";
import Button from "components/common/Button";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { format_datetime } from "lib/utils";
import { Event } from "api/Api";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Grid from "components/common/containers/Grid";

const EventsRoute = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [events, , paginatedData] = usePaginatedData({
    endpoint: apiClient.metrics.metricsEventsUserList,
    map: (event) => event,
  });
  const { refresh } = paginatedData;

  useEffect(() => {
    const checkCanDebug = async () => {
      const response = await apiClient.metrics.canDebug();
      if (!response.data.can_debug) {
        navigate("/");
      }
    };
    checkCanDebug();
  }, [apiClient.metrics, navigate]);

  return (
    <Rows className="grow gap-sm p-md">
      <Button
        variant="solid"
        icon="refresh"
        text="Refresh"
        className="mb-sm shrink-0"
        onClick={refresh}
      />
      <Columns className="grow">
        <PaginatedTableView
          className="basis-0 grow"
          results={events}
          paginatedData={paginatedData}
          searchMode="search"
          columns={[{ name: "Created" }, { name: "Summary" }]}
          onSelect={setSelectedEvent}
          isSelected={(event) => event === selectedEvent}
          renderRow={(event, Cell, Row) => {
            return (
              <Row key={event.id!.toString()}>
                <Cell>
                  {event.created ? format_datetime(event.created) : "unknown"}
                </Cell>
                <Cell>{event.prompt_name ?? event.kind}</Cell>
              </Row>
            );
          }}
        />
        <Rows className="grow basis-0 overflow-y-auto">
          <Grid className="grid grid-cols-[fit-content(100%)_auto] p-sm">
            {selectedEvent
              ? Object.entries(selectedEvent)
                  .filter(([key, value]) => !!value)
                  .map(([key, value]) => (
                    <>
                      <p className="text-secondary text-sm border-b pr-sm py-md">
                        {key}
                      </p>
                      <pre className="font-[Inter] whitespace-pre-wrap border-b py-md">
                        {typeof value === "string"
                          ? value
                          : JSON.stringify(value, null, 2)}
                      </pre>
                    </>
                  ))
              : null}
          </Grid>
        </Rows>
      </Columns>
    </Rows>
  );
};

export default EventsRoute;
