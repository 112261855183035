import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Icon, { IconName } from "components/common/Icon";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface OdoToastCTA {
  text: string;
  // If the onClick is a string, it will be used as the navigation target
  // If the onClick is a function, it will be called when the button is clicked
  onClick: () => void;
  undo?: Omit<OdoToastCTA, "undo">;
}

interface OdoToastOptions {
  icon?: IconName;
  iconClassName?: string;
  title: string;
  text: string;
  cta?: OdoToastCTA;
}

const OdoToastContent: FC<OdoToastOptions> = ({
  title,
  text,
  cta,
  icon,
  iconClassName,
}) => {
  const [wasClicked, setWasClicked] = useState(false);

  return (
    <Rows className="overflow-visible gap-xs">
      <Columns className="items-center gap-md">
        {icon && <Icon name={icon} variant="solid" className={iconClassName} />}
        <p className="text-md font-semibold">{title}</p>
      </Columns>
      <Columns className="items-center -mr-[16px] gap-sm">
        <p className="text-sm grow">{text}</p>
        {cta && (!wasClicked || !cta.undo) && (
          <Button
            text={cta.text}
            variant="solid-secondary"
            onClick={() => {
              setWasClicked(true);
              cta.onClick();
            }}
          />
        )}
        {cta && wasClicked && cta.undo?.onClick && (
          <Button
            text="Back"
            variant="outline"
            onClick={() => {
              setWasClicked(false);
              cta.undo?.onClick();
            }}
          />
        )}
      </Columns>
    </Rows>
  );
};

export const odoToast = {
  success: (options: OdoToastOptions) => {
    toast.success(
      <OdoToastContent
        icon="circle-check"
        iconClassName="text-additive"
        {...options}
      />,
      {
        position: "top-center",
        icon: false,
        progressStyle: {
          backgroundColor: "var(--additive)",
        },
      }
    );
  },
  warning: (options: OdoToastOptions) => {
    toast.warning(
      <OdoToastContent
        icon="circle-exclamation"
        iconClassName="text-warning"
        {...options}
      />,
      {
        position: "top-center",
        icon: false,
        progressStyle: {
          backgroundColor: "var(--warning)",
        },
      }
    );
  },
  error: (options: OdoToastOptions) => {
    toast.error(
      <OdoToastContent
        icon="circle-exclamation"
        iconClassName="text-destructive"
        {...options}
      />,
      {
        position: "top-right",
        icon: false,
        progressStyle: {
          backgroundColor: "var(--destructive)",
        },
      }
    );
  },
  caughtError: (error: unknown, action?: string) => {
    odoToast.error({
      title: action ? `Error ${action}` : "Error",
      text: message_from_exception(error),
    });
  },
};
